/* eslint-disable */
/// Autogenerated by 'scripts/compile-ajv-schema.js' target 'schemas'
const validate = validate14;
const pattern0 = new RegExp('^[0-9a-z]{64}$', 'u');
function validate14(
  data,
  { instancePath = '', parentData, parentDataProperty, rootData = data } = {}
) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.publicKey === undefined) {
      const err0 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'publicKey' },
        message: "must have required property '" + 'publicKey' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.transaction === undefined) {
      const err1 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'transaction' },
        message: "must have required property '" + 'transaction' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.origin === undefined) {
      const err2 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'origin' },
        message: "must have required property '" + 'origin' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    for (const key0 in data) {
      if (
        !(key0 === 'publicKey' || key0 === 'origin' || key0 === 'transaction')
      ) {
        const err3 = {
          instancePath,
          schemaPath: '#/additionalProperties',
          keyword: 'additionalProperties',
          params: { additionalProperty: key0 },
          message: 'must NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.publicKey !== undefined) {
      let data0 = data.publicKey;
      if (typeof data0 === 'string') {
        if (!pattern0.test(data0)) {
          const err4 = {
            instancePath: instancePath + '/publicKey',
            schemaPath: '#/properties/publicKey/pattern',
            keyword: 'pattern',
            params: { pattern: '^[0-9a-z]{64}$' },
            message: 'must match pattern "' + '^[0-9a-z]{64}$' + '"',
          };
          if (vErrors === null) {
            vErrors = [err4];
          } else {
            vErrors.push(err4);
          }
          errors++;
        }
      } else {
        const err5 = {
          instancePath: instancePath + '/publicKey',
          schemaPath: '#/properties/publicKey/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string',
        };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
      if (errors > 0) {
        const emErrs0 = [];
        for (const err6 of vErrors) {
          if (
            err6.keyword !== 'errorMessage' &&
            !err6.emUsed &&
            (err6.instancePath === instancePath + '/publicKey' ||
              (err6.instancePath.indexOf(instancePath + '/publicKey') === 0 &&
                err6.instancePath[instancePath + '/publicKey'.length] ===
                  '/')) &&
            err6.schemaPath.indexOf('#/properties/publicKey') === 0 &&
            err6.schemaPath['#/properties/publicKey'.length] === '/'
          ) {
            emErrs0.push(err6);
            err6.emUsed = true;
          }
        }
        if (emErrs0.length) {
          const err7 = {
            instancePath: instancePath + '/publicKey',
            schemaPath: '#/properties/publicKey/errorMessage',
            keyword: 'errorMessage',
            params: { errors: emErrs0 },
            message: '`publicKey` must be a 64 character hex encoded publicKey',
          };
          if (vErrors === null) {
            vErrors = [err7];
          } else {
            vErrors.push(err7);
          }
          errors++;
        }
        const emErrs1 = [];
        for (const err8 of vErrors) {
          if (!err8.emUsed) {
            emErrs1.push(err8);
          }
        }
        vErrors = emErrs1;
        errors = emErrs1.length;
      }
    }
    if (data.origin !== undefined) {
      if (typeof data.origin !== 'string') {
        const err9 = {
          instancePath: instancePath + '/origin',
          schemaPath: '#/properties/origin/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string',
        };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
    }
    if (data.transaction !== undefined) {
      let data2 = data.transaction;
      if (!(data2 && typeof data2 == 'object' && !Array.isArray(data2))) {
        const err10 = {
          instancePath: instancePath + '/transaction',
          schemaPath: '#/properties/transaction/type',
          keyword: 'type',
          params: { type: 'object' },
          message: 'must be object',
        };
        if (vErrors === null) {
          vErrors = [err10];
        } else {
          vErrors.push(err10);
        }
        errors++;
      }
      const _errs8 = errors;
      let valid1 = false;
      let passing0 = null;
      const _errs9 = errors;
      if (data2 && typeof data2 == 'object' && !Array.isArray(data2)) {
        if (data2.orderSubmission === undefined) {
          const err11 = {
            instancePath: instancePath + '/transaction',
            schemaPath: '#/properties/transaction/oneOf/0/required',
            keyword: 'required',
            params: { missingProperty: 'orderSubmission' },
            message: "must have required property '" + 'orderSubmission' + "'",
          };
          if (vErrors === null) {
            vErrors = [err11];
          } else {
            vErrors.push(err11);
          }
          errors++;
        }
        for (const key1 in data2) {
          if (!(key1 === 'orderSubmission')) {
            const err12 = {
              instancePath: instancePath + '/transaction',
              schemaPath:
                '#/properties/transaction/oneOf/0/additionalProperties',
              keyword: 'additionalProperties',
              params: { additionalProperty: key1 },
              message: 'must NOT have additional properties',
            };
            if (vErrors === null) {
              vErrors = [err12];
            } else {
              vErrors.push(err12);
            }
            errors++;
          }
        }
      }
      var _valid0 = _errs9 === errors;
      if (_valid0) {
        valid1 = true;
        passing0 = 0;
      }
      const _errs11 = errors;
      if (data2 && typeof data2 == 'object' && !Array.isArray(data2)) {
        if (data2.orderCancellation === undefined) {
          const err13 = {
            instancePath: instancePath + '/transaction',
            schemaPath: '#/properties/transaction/oneOf/1/required',
            keyword: 'required',
            params: { missingProperty: 'orderCancellation' },
            message:
              "must have required property '" + 'orderCancellation' + "'",
          };
          if (vErrors === null) {
            vErrors = [err13];
          } else {
            vErrors.push(err13);
          }
          errors++;
        }
        for (const key2 in data2) {
          if (!(key2 === 'orderCancellation')) {
            const err14 = {
              instancePath: instancePath + '/transaction',
              schemaPath:
                '#/properties/transaction/oneOf/1/additionalProperties',
              keyword: 'additionalProperties',
              params: { additionalProperty: key2 },
              message: 'must NOT have additional properties',
            };
            if (vErrors === null) {
              vErrors = [err14];
            } else {
              vErrors.push(err14);
            }
            errors++;
          }
        }
      }
      var _valid0 = _errs11 === errors;
      if (_valid0 && valid1) {
        valid1 = false;
        passing0 = [passing0, 1];
      } else {
        if (_valid0) {
          valid1 = true;
          passing0 = 1;
        }
        const _errs13 = errors;
        if (data2 && typeof data2 == 'object' && !Array.isArray(data2)) {
          if (data2.orderAmendment === undefined) {
            const err15 = {
              instancePath: instancePath + '/transaction',
              schemaPath: '#/properties/transaction/oneOf/2/required',
              keyword: 'required',
              params: { missingProperty: 'orderAmendment' },
              message: "must have required property '" + 'orderAmendment' + "'",
            };
            if (vErrors === null) {
              vErrors = [err15];
            } else {
              vErrors.push(err15);
            }
            errors++;
          }
          for (const key3 in data2) {
            if (!(key3 === 'orderAmendment')) {
              const err16 = {
                instancePath: instancePath + '/transaction',
                schemaPath:
                  '#/properties/transaction/oneOf/2/additionalProperties',
                keyword: 'additionalProperties',
                params: { additionalProperty: key3 },
                message: 'must NOT have additional properties',
              };
              if (vErrors === null) {
                vErrors = [err16];
              } else {
                vErrors.push(err16);
              }
              errors++;
            }
          }
        }
        var _valid0 = _errs13 === errors;
        if (_valid0 && valid1) {
          valid1 = false;
          passing0 = [passing0, 2];
        } else {
          if (_valid0) {
            valid1 = true;
            passing0 = 2;
          }
          const _errs15 = errors;
          if (data2 && typeof data2 == 'object' && !Array.isArray(data2)) {
            if (data2.withdrawSubmission === undefined) {
              const err17 = {
                instancePath: instancePath + '/transaction',
                schemaPath: '#/properties/transaction/oneOf/3/required',
                keyword: 'required',
                params: { missingProperty: 'withdrawSubmission' },
                message:
                  "must have required property '" + 'withdrawSubmission' + "'",
              };
              if (vErrors === null) {
                vErrors = [err17];
              } else {
                vErrors.push(err17);
              }
              errors++;
            }
            for (const key4 in data2) {
              if (!(key4 === 'withdrawSubmission')) {
                const err18 = {
                  instancePath: instancePath + '/transaction',
                  schemaPath:
                    '#/properties/transaction/oneOf/3/additionalProperties',
                  keyword: 'additionalProperties',
                  params: { additionalProperty: key4 },
                  message: 'must NOT have additional properties',
                };
                if (vErrors === null) {
                  vErrors = [err18];
                } else {
                  vErrors.push(err18);
                }
                errors++;
              }
            }
          }
          var _valid0 = _errs15 === errors;
          if (_valid0 && valid1) {
            valid1 = false;
            passing0 = [passing0, 3];
          } else {
            if (_valid0) {
              valid1 = true;
              passing0 = 3;
            }
            const _errs17 = errors;
            if (data2 && typeof data2 == 'object' && !Array.isArray(data2)) {
              if (data2.proposalSubmission === undefined) {
                const err19 = {
                  instancePath: instancePath + '/transaction',
                  schemaPath: '#/properties/transaction/oneOf/4/required',
                  keyword: 'required',
                  params: { missingProperty: 'proposalSubmission' },
                  message:
                    "must have required property '" +
                    'proposalSubmission' +
                    "'",
                };
                if (vErrors === null) {
                  vErrors = [err19];
                } else {
                  vErrors.push(err19);
                }
                errors++;
              }
              for (const key5 in data2) {
                if (!(key5 === 'proposalSubmission')) {
                  const err20 = {
                    instancePath: instancePath + '/transaction',
                    schemaPath:
                      '#/properties/transaction/oneOf/4/additionalProperties',
                    keyword: 'additionalProperties',
                    params: { additionalProperty: key5 },
                    message: 'must NOT have additional properties',
                  };
                  if (vErrors === null) {
                    vErrors = [err20];
                  } else {
                    vErrors.push(err20);
                  }
                  errors++;
                }
              }
            }
            var _valid0 = _errs17 === errors;
            if (_valid0 && valid1) {
              valid1 = false;
              passing0 = [passing0, 4];
            } else {
              if (_valid0) {
                valid1 = true;
                passing0 = 4;
              }
              const _errs19 = errors;
              if (data2 && typeof data2 == 'object' && !Array.isArray(data2)) {
                if (data2.voteSubmission === undefined) {
                  const err21 = {
                    instancePath: instancePath + '/transaction',
                    schemaPath: '#/properties/transaction/oneOf/5/required',
                    keyword: 'required',
                    params: { missingProperty: 'voteSubmission' },
                    message:
                      "must have required property '" + 'voteSubmission' + "'",
                  };
                  if (vErrors === null) {
                    vErrors = [err21];
                  } else {
                    vErrors.push(err21);
                  }
                  errors++;
                }
                for (const key6 in data2) {
                  if (!(key6 === 'voteSubmission')) {
                    const err22 = {
                      instancePath: instancePath + '/transaction',
                      schemaPath:
                        '#/properties/transaction/oneOf/5/additionalProperties',
                      keyword: 'additionalProperties',
                      params: { additionalProperty: key6 },
                      message: 'must NOT have additional properties',
                    };
                    if (vErrors === null) {
                      vErrors = [err22];
                    } else {
                      vErrors.push(err22);
                    }
                    errors++;
                  }
                }
              }
              var _valid0 = _errs19 === errors;
              if (_valid0 && valid1) {
                valid1 = false;
                passing0 = [passing0, 5];
              } else {
                if (_valid0) {
                  valid1 = true;
                  passing0 = 5;
                }
                const _errs21 = errors;
                if (
                  data2 &&
                  typeof data2 == 'object' &&
                  !Array.isArray(data2)
                ) {
                  if (data2.liquidityProvisionSubmission === undefined) {
                    const err23 = {
                      instancePath: instancePath + '/transaction',
                      schemaPath: '#/properties/transaction/oneOf/6/required',
                      keyword: 'required',
                      params: {
                        missingProperty: 'liquidityProvisionSubmission',
                      },
                      message:
                        "must have required property '" +
                        'liquidityProvisionSubmission' +
                        "'",
                    };
                    if (vErrors === null) {
                      vErrors = [err23];
                    } else {
                      vErrors.push(err23);
                    }
                    errors++;
                  }
                  for (const key7 in data2) {
                    if (!(key7 === 'liquidityProvisionSubmission')) {
                      const err24 = {
                        instancePath: instancePath + '/transaction',
                        schemaPath:
                          '#/properties/transaction/oneOf/6/additionalProperties',
                        keyword: 'additionalProperties',
                        params: { additionalProperty: key7 },
                        message: 'must NOT have additional properties',
                      };
                      if (vErrors === null) {
                        vErrors = [err24];
                      } else {
                        vErrors.push(err24);
                      }
                      errors++;
                    }
                  }
                }
                var _valid0 = _errs21 === errors;
                if (_valid0 && valid1) {
                  valid1 = false;
                  passing0 = [passing0, 6];
                } else {
                  if (_valid0) {
                    valid1 = true;
                    passing0 = 6;
                  }
                  const _errs23 = errors;
                  if (
                    data2 &&
                    typeof data2 == 'object' &&
                    !Array.isArray(data2)
                  ) {
                    if (data2.delegateSubmission === undefined) {
                      const err25 = {
                        instancePath: instancePath + '/transaction',
                        schemaPath: '#/properties/transaction/oneOf/7/required',
                        keyword: 'required',
                        params: { missingProperty: 'delegateSubmission' },
                        message:
                          "must have required property '" +
                          'delegateSubmission' +
                          "'",
                      };
                      if (vErrors === null) {
                        vErrors = [err25];
                      } else {
                        vErrors.push(err25);
                      }
                      errors++;
                    }
                    for (const key8 in data2) {
                      if (!(key8 === 'delegateSubmission')) {
                        const err26 = {
                          instancePath: instancePath + '/transaction',
                          schemaPath:
                            '#/properties/transaction/oneOf/7/additionalProperties',
                          keyword: 'additionalProperties',
                          params: { additionalProperty: key8 },
                          message: 'must NOT have additional properties',
                        };
                        if (vErrors === null) {
                          vErrors = [err26];
                        } else {
                          vErrors.push(err26);
                        }
                        errors++;
                      }
                    }
                  }
                  var _valid0 = _errs23 === errors;
                  if (_valid0 && valid1) {
                    valid1 = false;
                    passing0 = [passing0, 7];
                  } else {
                    if (_valid0) {
                      valid1 = true;
                      passing0 = 7;
                    }
                    const _errs25 = errors;
                    if (
                      data2 &&
                      typeof data2 == 'object' &&
                      !Array.isArray(data2)
                    ) {
                      if (data2.undelegateSubmission === undefined) {
                        const err27 = {
                          instancePath: instancePath + '/transaction',
                          schemaPath:
                            '#/properties/transaction/oneOf/8/required',
                          keyword: 'required',
                          params: { missingProperty: 'undelegateSubmission' },
                          message:
                            "must have required property '" +
                            'undelegateSubmission' +
                            "'",
                        };
                        if (vErrors === null) {
                          vErrors = [err27];
                        } else {
                          vErrors.push(err27);
                        }
                        errors++;
                      }
                      for (const key9 in data2) {
                        if (!(key9 === 'undelegateSubmission')) {
                          const err28 = {
                            instancePath: instancePath + '/transaction',
                            schemaPath:
                              '#/properties/transaction/oneOf/8/additionalProperties',
                            keyword: 'additionalProperties',
                            params: { additionalProperty: key9 },
                            message: 'must NOT have additional properties',
                          };
                          if (vErrors === null) {
                            vErrors = [err28];
                          } else {
                            vErrors.push(err28);
                          }
                          errors++;
                        }
                      }
                    }
                    var _valid0 = _errs25 === errors;
                    if (_valid0 && valid1) {
                      valid1 = false;
                      passing0 = [passing0, 8];
                    } else {
                      if (_valid0) {
                        valid1 = true;
                        passing0 = 8;
                      }
                      const _errs27 = errors;
                      if (
                        data2 &&
                        typeof data2 == 'object' &&
                        !Array.isArray(data2)
                      ) {
                        if (
                          data2.liquidityProvisionCancellation === undefined
                        ) {
                          const err29 = {
                            instancePath: instancePath + '/transaction',
                            schemaPath:
                              '#/properties/transaction/oneOf/9/required',
                            keyword: 'required',
                            params: {
                              missingProperty: 'liquidityProvisionCancellation',
                            },
                            message:
                              "must have required property '" +
                              'liquidityProvisionCancellation' +
                              "'",
                          };
                          if (vErrors === null) {
                            vErrors = [err29];
                          } else {
                            vErrors.push(err29);
                          }
                          errors++;
                        }
                        for (const key10 in data2) {
                          if (!(key10 === 'liquidityProvisionCancellation')) {
                            const err30 = {
                              instancePath: instancePath + '/transaction',
                              schemaPath:
                                '#/properties/transaction/oneOf/9/additionalProperties',
                              keyword: 'additionalProperties',
                              params: { additionalProperty: key10 },
                              message: 'must NOT have additional properties',
                            };
                            if (vErrors === null) {
                              vErrors = [err30];
                            } else {
                              vErrors.push(err30);
                            }
                            errors++;
                          }
                        }
                      }
                      var _valid0 = _errs27 === errors;
                      if (_valid0 && valid1) {
                        valid1 = false;
                        passing0 = [passing0, 9];
                      } else {
                        if (_valid0) {
                          valid1 = true;
                          passing0 = 9;
                        }
                        const _errs29 = errors;
                        if (
                          data2 &&
                          typeof data2 == 'object' &&
                          !Array.isArray(data2)
                        ) {
                          if (data2.liquidityProvisionAmendment === undefined) {
                            const err31 = {
                              instancePath: instancePath + '/transaction',
                              schemaPath:
                                '#/properties/transaction/oneOf/10/required',
                              keyword: 'required',
                              params: {
                                missingProperty: 'liquidityProvisionAmendment',
                              },
                              message:
                                "must have required property '" +
                                'liquidityProvisionAmendment' +
                                "'",
                            };
                            if (vErrors === null) {
                              vErrors = [err31];
                            } else {
                              vErrors.push(err31);
                            }
                            errors++;
                          }
                          for (const key11 in data2) {
                            if (!(key11 === 'liquidityProvisionAmendment')) {
                              const err32 = {
                                instancePath: instancePath + '/transaction',
                                schemaPath:
                                  '#/properties/transaction/oneOf/10/additionalProperties',
                                keyword: 'additionalProperties',
                                params: { additionalProperty: key11 },
                                message: 'must NOT have additional properties',
                              };
                              if (vErrors === null) {
                                vErrors = [err32];
                              } else {
                                vErrors.push(err32);
                              }
                              errors++;
                            }
                          }
                        }
                        var _valid0 = _errs29 === errors;
                        if (_valid0 && valid1) {
                          valid1 = false;
                          passing0 = [passing0, 10];
                        } else {
                          if (_valid0) {
                            valid1 = true;
                            passing0 = 10;
                          }
                          const _errs31 = errors;
                          if (
                            data2 &&
                            typeof data2 == 'object' &&
                            !Array.isArray(data2)
                          ) {
                            if (data2.transfer === undefined) {
                              const err33 = {
                                instancePath: instancePath + '/transaction',
                                schemaPath:
                                  '#/properties/transaction/oneOf/11/required',
                                keyword: 'required',
                                params: { missingProperty: 'transfer' },
                                message:
                                  "must have required property '" +
                                  'transfer' +
                                  "'",
                              };
                              if (vErrors === null) {
                                vErrors = [err33];
                              } else {
                                vErrors.push(err33);
                              }
                              errors++;
                            }
                            for (const key12 in data2) {
                              if (!(key12 === 'transfer')) {
                                const err34 = {
                                  instancePath: instancePath + '/transaction',
                                  schemaPath:
                                    '#/properties/transaction/oneOf/11/additionalProperties',
                                  keyword: 'additionalProperties',
                                  params: { additionalProperty: key12 },
                                  message:
                                    'must NOT have additional properties',
                                };
                                if (vErrors === null) {
                                  vErrors = [err34];
                                } else {
                                  vErrors.push(err34);
                                }
                                errors++;
                              }
                            }
                          }
                          var _valid0 = _errs31 === errors;
                          if (_valid0 && valid1) {
                            valid1 = false;
                            passing0 = [passing0, 11];
                          } else {
                            if (_valid0) {
                              valid1 = true;
                              passing0 = 11;
                            }
                            const _errs33 = errors;
                            if (
                              data2 &&
                              typeof data2 == 'object' &&
                              !Array.isArray(data2)
                            ) {
                              if (data2.cancelTransfer === undefined) {
                                const err35 = {
                                  instancePath: instancePath + '/transaction',
                                  schemaPath:
                                    '#/properties/transaction/oneOf/12/required',
                                  keyword: 'required',
                                  params: { missingProperty: 'cancelTransfer' },
                                  message:
                                    "must have required property '" +
                                    'cancelTransfer' +
                                    "'",
                                };
                                if (vErrors === null) {
                                  vErrors = [err35];
                                } else {
                                  vErrors.push(err35);
                                }
                                errors++;
                              }
                              for (const key13 in data2) {
                                if (!(key13 === 'cancelTransfer')) {
                                  const err36 = {
                                    instancePath: instancePath + '/transaction',
                                    schemaPath:
                                      '#/properties/transaction/oneOf/12/additionalProperties',
                                    keyword: 'additionalProperties',
                                    params: { additionalProperty: key13 },
                                    message:
                                      'must NOT have additional properties',
                                  };
                                  if (vErrors === null) {
                                    vErrors = [err36];
                                  } else {
                                    vErrors.push(err36);
                                  }
                                  errors++;
                                }
                              }
                            }
                            var _valid0 = _errs33 === errors;
                            if (_valid0 && valid1) {
                              valid1 = false;
                              passing0 = [passing0, 12];
                            } else {
                              if (_valid0) {
                                valid1 = true;
                                passing0 = 12;
                              }
                              const _errs35 = errors;
                              if (
                                data2 &&
                                typeof data2 == 'object' &&
                                !Array.isArray(data2)
                              ) {
                                if (data2.announceNode === undefined) {
                                  const err37 = {
                                    instancePath: instancePath + '/transaction',
                                    schemaPath:
                                      '#/properties/transaction/oneOf/13/required',
                                    keyword: 'required',
                                    params: { missingProperty: 'announceNode' },
                                    message:
                                      "must have required property '" +
                                      'announceNode' +
                                      "'",
                                  };
                                  if (vErrors === null) {
                                    vErrors = [err37];
                                  } else {
                                    vErrors.push(err37);
                                  }
                                  errors++;
                                }
                                for (const key14 in data2) {
                                  if (!(key14 === 'announceNode')) {
                                    const err38 = {
                                      instancePath:
                                        instancePath + '/transaction',
                                      schemaPath:
                                        '#/properties/transaction/oneOf/13/additionalProperties',
                                      keyword: 'additionalProperties',
                                      params: { additionalProperty: key14 },
                                      message:
                                        'must NOT have additional properties',
                                    };
                                    if (vErrors === null) {
                                      vErrors = [err38];
                                    } else {
                                      vErrors.push(err38);
                                    }
                                    errors++;
                                  }
                                }
                              }
                              var _valid0 = _errs35 === errors;
                              if (_valid0 && valid1) {
                                valid1 = false;
                                passing0 = [passing0, 13];
                              } else {
                                if (_valid0) {
                                  valid1 = true;
                                  passing0 = 13;
                                }
                                const _errs37 = errors;
                                if (
                                  data2 &&
                                  typeof data2 == 'object' &&
                                  !Array.isArray(data2)
                                ) {
                                  if (
                                    data2.batchMarketInstructions === undefined
                                  ) {
                                    const err39 = {
                                      instancePath:
                                        instancePath + '/transaction',
                                      schemaPath:
                                        '#/properties/transaction/oneOf/14/required',
                                      keyword: 'required',
                                      params: {
                                        missingProperty:
                                          'batchMarketInstructions',
                                      },
                                      message:
                                        "must have required property '" +
                                        'batchMarketInstructions' +
                                        "'",
                                    };
                                    if (vErrors === null) {
                                      vErrors = [err39];
                                    } else {
                                      vErrors.push(err39);
                                    }
                                    errors++;
                                  }
                                  for (const key15 in data2) {
                                    if (
                                      !(key15 === 'batchMarketInstructions')
                                    ) {
                                      const err40 = {
                                        instancePath:
                                          instancePath + '/transaction',
                                        schemaPath:
                                          '#/properties/transaction/oneOf/14/additionalProperties',
                                        keyword: 'additionalProperties',
                                        params: { additionalProperty: key15 },
                                        message:
                                          'must NOT have additional properties',
                                      };
                                      if (vErrors === null) {
                                        vErrors = [err40];
                                      } else {
                                        vErrors.push(err40);
                                      }
                                      errors++;
                                    }
                                  }
                                }
                                var _valid0 = _errs37 === errors;
                                if (_valid0 && valid1) {
                                  valid1 = false;
                                  passing0 = [passing0, 14];
                                } else {
                                  if (_valid0) {
                                    valid1 = true;
                                    passing0 = 14;
                                  }
                                  const _errs39 = errors;
                                  if (
                                    data2 &&
                                    typeof data2 == 'object' &&
                                    !Array.isArray(data2)
                                  ) {
                                    if (
                                      data2.stopOrdersSubmission === undefined
                                    ) {
                                      const err41 = {
                                        instancePath:
                                          instancePath + '/transaction',
                                        schemaPath:
                                          '#/properties/transaction/oneOf/15/required',
                                        keyword: 'required',
                                        params: {
                                          missingProperty:
                                            'stopOrdersSubmission',
                                        },
                                        message:
                                          "must have required property '" +
                                          'stopOrdersSubmission' +
                                          "'",
                                      };
                                      if (vErrors === null) {
                                        vErrors = [err41];
                                      } else {
                                        vErrors.push(err41);
                                      }
                                      errors++;
                                    }
                                    for (const key16 in data2) {
                                      if (!(key16 === 'stopOrdersSubmission')) {
                                        const err42 = {
                                          instancePath:
                                            instancePath + '/transaction',
                                          schemaPath:
                                            '#/properties/transaction/oneOf/15/additionalProperties',
                                          keyword: 'additionalProperties',
                                          params: { additionalProperty: key16 },
                                          message:
                                            'must NOT have additional properties',
                                        };
                                        if (vErrors === null) {
                                          vErrors = [err42];
                                        } else {
                                          vErrors.push(err42);
                                        }
                                        errors++;
                                      }
                                    }
                                  }
                                  var _valid0 = _errs39 === errors;
                                  if (_valid0 && valid1) {
                                    valid1 = false;
                                    passing0 = [passing0, 15];
                                  } else {
                                    if (_valid0) {
                                      valid1 = true;
                                      passing0 = 15;
                                    }
                                    const _errs41 = errors;
                                    if (
                                      data2 &&
                                      typeof data2 == 'object' &&
                                      !Array.isArray(data2)
                                    ) {
                                      if (
                                        data2.stopOrdersCancellation ===
                                        undefined
                                      ) {
                                        const err43 = {
                                          instancePath:
                                            instancePath + '/transaction',
                                          schemaPath:
                                            '#/properties/transaction/oneOf/16/required',
                                          keyword: 'required',
                                          params: {
                                            missingProperty:
                                              'stopOrdersCancellation',
                                          },
                                          message:
                                            "must have required property '" +
                                            'stopOrdersCancellation' +
                                            "'",
                                        };
                                        if (vErrors === null) {
                                          vErrors = [err43];
                                        } else {
                                          vErrors.push(err43);
                                        }
                                        errors++;
                                      }
                                      for (const key17 in data2) {
                                        if (
                                          !(key17 === 'stopOrdersCancellation')
                                        ) {
                                          const err44 = {
                                            instancePath:
                                              instancePath + '/transaction',
                                            schemaPath:
                                              '#/properties/transaction/oneOf/16/additionalProperties',
                                            keyword: 'additionalProperties',
                                            params: {
                                              additionalProperty: key17,
                                            },
                                            message:
                                              'must NOT have additional properties',
                                          };
                                          if (vErrors === null) {
                                            vErrors = [err44];
                                          } else {
                                            vErrors.push(err44);
                                          }
                                          errors++;
                                        }
                                      }
                                    }
                                    var _valid0 = _errs41 === errors;
                                    if (_valid0 && valid1) {
                                      valid1 = false;
                                      passing0 = [passing0, 16];
                                    } else {
                                      if (_valid0) {
                                        valid1 = true;
                                        passing0 = 16;
                                      }
                                      const _errs43 = errors;
                                      if (
                                        data2 &&
                                        typeof data2 == 'object' &&
                                        !Array.isArray(data2)
                                      ) {
                                        if (
                                          data2.createReferralSet === undefined
                                        ) {
                                          const err45 = {
                                            instancePath:
                                              instancePath + '/transaction',
                                            schemaPath:
                                              '#/properties/transaction/oneOf/17/required',
                                            keyword: 'required',
                                            params: {
                                              missingProperty:
                                                'createReferralSet',
                                            },
                                            message:
                                              "must have required property '" +
                                              'createReferralSet' +
                                              "'",
                                          };
                                          if (vErrors === null) {
                                            vErrors = [err45];
                                          } else {
                                            vErrors.push(err45);
                                          }
                                          errors++;
                                        }
                                        for (const key18 in data2) {
                                          if (
                                            !(key18 === 'createReferralSet')
                                          ) {
                                            const err46 = {
                                              instancePath:
                                                instancePath + '/transaction',
                                              schemaPath:
                                                '#/properties/transaction/oneOf/17/additionalProperties',
                                              keyword: 'additionalProperties',
                                              params: {
                                                additionalProperty: key18,
                                              },
                                              message:
                                                'must NOT have additional properties',
                                            };
                                            if (vErrors === null) {
                                              vErrors = [err46];
                                            } else {
                                              vErrors.push(err46);
                                            }
                                            errors++;
                                          }
                                        }
                                      }
                                      var _valid0 = _errs43 === errors;
                                      if (_valid0 && valid1) {
                                        valid1 = false;
                                        passing0 = [passing0, 17];
                                      } else {
                                        if (_valid0) {
                                          valid1 = true;
                                          passing0 = 17;
                                        }
                                        const _errs45 = errors;
                                        if (
                                          data2 &&
                                          typeof data2 == 'object' &&
                                          !Array.isArray(data2)
                                        ) {
                                          if (
                                            data2.updateReferralSet ===
                                            undefined
                                          ) {
                                            const err47 = {
                                              instancePath:
                                                instancePath + '/transaction',
                                              schemaPath:
                                                '#/properties/transaction/oneOf/18/required',
                                              keyword: 'required',
                                              params: {
                                                missingProperty:
                                                  'updateReferralSet',
                                              },
                                              message:
                                                "must have required property '" +
                                                'updateReferralSet' +
                                                "'",
                                            };
                                            if (vErrors === null) {
                                              vErrors = [err47];
                                            } else {
                                              vErrors.push(err47);
                                            }
                                            errors++;
                                          }
                                          for (const key19 in data2) {
                                            if (
                                              !(key19 === 'updateReferralSet')
                                            ) {
                                              const err48 = {
                                                instancePath:
                                                  instancePath + '/transaction',
                                                schemaPath:
                                                  '#/properties/transaction/oneOf/18/additionalProperties',
                                                keyword: 'additionalProperties',
                                                params: {
                                                  additionalProperty: key19,
                                                },
                                                message:
                                                  'must NOT have additional properties',
                                              };
                                              if (vErrors === null) {
                                                vErrors = [err48];
                                              } else {
                                                vErrors.push(err48);
                                              }
                                              errors++;
                                            }
                                          }
                                        }
                                        var _valid0 = _errs45 === errors;
                                        if (_valid0 && valid1) {
                                          valid1 = false;
                                          passing0 = [passing0, 18];
                                        } else {
                                          if (_valid0) {
                                            valid1 = true;
                                            passing0 = 18;
                                          }
                                          const _errs47 = errors;
                                          if (
                                            data2 &&
                                            typeof data2 == 'object' &&
                                            !Array.isArray(data2)
                                          ) {
                                            if (
                                              data2.applyReferralCode ===
                                              undefined
                                            ) {
                                              const err49 = {
                                                instancePath:
                                                  instancePath + '/transaction',
                                                schemaPath:
                                                  '#/properties/transaction/oneOf/19/required',
                                                keyword: 'required',
                                                params: {
                                                  missingProperty:
                                                    'applyReferralCode',
                                                },
                                                message:
                                                  "must have required property '" +
                                                  'applyReferralCode' +
                                                  "'",
                                              };
                                              if (vErrors === null) {
                                                vErrors = [err49];
                                              } else {
                                                vErrors.push(err49);
                                              }
                                              errors++;
                                            }
                                            for (const key20 in data2) {
                                              if (
                                                !(key20 === 'applyReferralCode')
                                              ) {
                                                const err50 = {
                                                  instancePath:
                                                    instancePath +
                                                    '/transaction',
                                                  schemaPath:
                                                    '#/properties/transaction/oneOf/19/additionalProperties',
                                                  keyword:
                                                    'additionalProperties',
                                                  params: {
                                                    additionalProperty: key20,
                                                  },
                                                  message:
                                                    'must NOT have additional properties',
                                                };
                                                if (vErrors === null) {
                                                  vErrors = [err50];
                                                } else {
                                                  vErrors.push(err50);
                                                }
                                                errors++;
                                              }
                                            }
                                          }
                                          var _valid0 = _errs47 === errors;
                                          if (_valid0 && valid1) {
                                            valid1 = false;
                                            passing0 = [passing0, 19];
                                          } else {
                                            if (_valid0) {
                                              valid1 = true;
                                              passing0 = 19;
                                            }
                                            const _errs49 = errors;
                                            if (
                                              data2 &&
                                              typeof data2 == 'object' &&
                                              !Array.isArray(data2)
                                            ) {
                                              if (
                                                data2.updateMarginMode ===
                                                undefined
                                              ) {
                                                const err51 = {
                                                  instancePath:
                                                    instancePath +
                                                    '/transaction',
                                                  schemaPath:
                                                    '#/properties/transaction/oneOf/20/required',
                                                  keyword: 'required',
                                                  params: {
                                                    missingProperty:
                                                      'updateMarginMode',
                                                  },
                                                  message:
                                                    "must have required property '" +
                                                    'updateMarginMode' +
                                                    "'",
                                                };
                                                if (vErrors === null) {
                                                  vErrors = [err51];
                                                } else {
                                                  vErrors.push(err51);
                                                }
                                                errors++;
                                              }
                                              for (const key21 in data2) {
                                                if (
                                                  !(
                                                    key21 === 'updateMarginMode'
                                                  )
                                                ) {
                                                  const err52 = {
                                                    instancePath:
                                                      instancePath +
                                                      '/transaction',
                                                    schemaPath:
                                                      '#/properties/transaction/oneOf/20/additionalProperties',
                                                    keyword:
                                                      'additionalProperties',
                                                    params: {
                                                      additionalProperty: key21,
                                                    },
                                                    message:
                                                      'must NOT have additional properties',
                                                  };
                                                  if (vErrors === null) {
                                                    vErrors = [err52];
                                                  } else {
                                                    vErrors.push(err52);
                                                  }
                                                  errors++;
                                                }
                                              }
                                            }
                                            var _valid0 = _errs49 === errors;
                                            if (_valid0 && valid1) {
                                              valid1 = false;
                                              passing0 = [passing0, 20];
                                            } else {
                                              if (_valid0) {
                                                valid1 = true;
                                                passing0 = 20;
                                              }
                                              const _errs51 = errors;
                                              if (
                                                data2 &&
                                                typeof data2 == 'object' &&
                                                !Array.isArray(data2)
                                              ) {
                                                if (
                                                  data2.joinTeam === undefined
                                                ) {
                                                  const err53 = {
                                                    instancePath:
                                                      instancePath +
                                                      '/transaction',
                                                    schemaPath:
                                                      '#/properties/transaction/oneOf/21/required',
                                                    keyword: 'required',
                                                    params: {
                                                      missingProperty:
                                                        'joinTeam',
                                                    },
                                                    message:
                                                      "must have required property '" +
                                                      'joinTeam' +
                                                      "'",
                                                  };
                                                  if (vErrors === null) {
                                                    vErrors = [err53];
                                                  } else {
                                                    vErrors.push(err53);
                                                  }
                                                  errors++;
                                                }
                                                for (const key22 in data2) {
                                                  if (!(key22 === 'joinTeam')) {
                                                    const err54 = {
                                                      instancePath:
                                                        instancePath +
                                                        '/transaction',
                                                      schemaPath:
                                                        '#/properties/transaction/oneOf/21/additionalProperties',
                                                      keyword:
                                                        'additionalProperties',
                                                      params: {
                                                        additionalProperty:
                                                          key22,
                                                      },
                                                      message:
                                                        'must NOT have additional properties',
                                                    };
                                                    if (vErrors === null) {
                                                      vErrors = [err54];
                                                    } else {
                                                      vErrors.push(err54);
                                                    }
                                                    errors++;
                                                  }
                                                }
                                              }
                                              var _valid0 = _errs51 === errors;
                                              if (_valid0 && valid1) {
                                                valid1 = false;
                                                passing0 = [passing0, 21];
                                              } else {
                                                if (_valid0) {
                                                  valid1 = true;
                                                  passing0 = 21;
                                                }
                                                const _errs53 = errors;
                                                if (
                                                  data2 &&
                                                  typeof data2 == 'object' &&
                                                  !Array.isArray(data2)
                                                ) {
                                                  if (
                                                    data2.batchProposalSubmission ===
                                                    undefined
                                                  ) {
                                                    const err55 = {
                                                      instancePath:
                                                        instancePath +
                                                        '/transaction',
                                                      schemaPath:
                                                        '#/properties/transaction/oneOf/22/required',
                                                      keyword: 'required',
                                                      params: {
                                                        missingProperty:
                                                          'batchProposalSubmission',
                                                      },
                                                      message:
                                                        "must have required property '" +
                                                        'batchProposalSubmission' +
                                                        "'",
                                                    };
                                                    if (vErrors === null) {
                                                      vErrors = [err55];
                                                    } else {
                                                      vErrors.push(err55);
                                                    }
                                                    errors++;
                                                  }
                                                  for (const key23 in data2) {
                                                    if (
                                                      !(
                                                        key23 ===
                                                        'batchProposalSubmission'
                                                      )
                                                    ) {
                                                      const err56 = {
                                                        instancePath:
                                                          instancePath +
                                                          '/transaction',
                                                        schemaPath:
                                                          '#/properties/transaction/oneOf/22/additionalProperties',
                                                        keyword:
                                                          'additionalProperties',
                                                        params: {
                                                          additionalProperty:
                                                            key23,
                                                        },
                                                        message:
                                                          'must NOT have additional properties',
                                                      };
                                                      if (vErrors === null) {
                                                        vErrors = [err56];
                                                      } else {
                                                        vErrors.push(err56);
                                                      }
                                                      errors++;
                                                    }
                                                  }
                                                }
                                                var _valid0 =
                                                  _errs53 === errors;
                                                if (_valid0 && valid1) {
                                                  valid1 = false;
                                                  passing0 = [passing0, 22];
                                                } else {
                                                  if (_valid0) {
                                                    valid1 = true;
                                                    passing0 = 22;
                                                  }
                                                  const _errs55 = errors;
                                                  if (
                                                    data2 &&
                                                    typeof data2 == 'object' &&
                                                    !Array.isArray(data2)
                                                  ) {
                                                    if (
                                                      data2.updatePartyProfile ===
                                                      undefined
                                                    ) {
                                                      const err57 = {
                                                        instancePath:
                                                          instancePath +
                                                          '/transaction',
                                                        schemaPath:
                                                          '#/properties/transaction/oneOf/23/required',
                                                        keyword: 'required',
                                                        params: {
                                                          missingProperty:
                                                            'updatePartyProfile',
                                                        },
                                                        message:
                                                          "must have required property '" +
                                                          'updatePartyProfile' +
                                                          "'",
                                                      };
                                                      if (vErrors === null) {
                                                        vErrors = [err57];
                                                      } else {
                                                        vErrors.push(err57);
                                                      }
                                                      errors++;
                                                    }
                                                    for (const key24 in data2) {
                                                      if (
                                                        !(
                                                          key24 ===
                                                          'updatePartyProfile'
                                                        )
                                                      ) {
                                                        const err58 = {
                                                          instancePath:
                                                            instancePath +
                                                            '/transaction',
                                                          schemaPath:
                                                            '#/properties/transaction/oneOf/23/additionalProperties',
                                                          keyword:
                                                            'additionalProperties',
                                                          params: {
                                                            additionalProperty:
                                                              key24,
                                                          },
                                                          message:
                                                            'must NOT have additional properties',
                                                        };
                                                        if (vErrors === null) {
                                                          vErrors = [err58];
                                                        } else {
                                                          vErrors.push(err58);
                                                        }
                                                        errors++;
                                                      }
                                                    }
                                                  }
                                                  var _valid0 =
                                                    _errs55 === errors;
                                                  if (_valid0 && valid1) {
                                                    valid1 = false;
                                                    passing0 = [passing0, 23];
                                                  } else {
                                                    if (_valid0) {
                                                      valid1 = true;
                                                      passing0 = 23;
                                                    }
                                                    const _errs57 = errors;
                                                    if (
                                                      data2 &&
                                                      typeof data2 ==
                                                        'object' &&
                                                      !Array.isArray(data2)
                                                    ) {
                                                      if (
                                                        data2.submitAmm ===
                                                        undefined
                                                      ) {
                                                        const err59 = {
                                                          instancePath:
                                                            instancePath +
                                                            '/transaction',
                                                          schemaPath:
                                                            '#/properties/transaction/oneOf/24/required',
                                                          keyword: 'required',
                                                          params: {
                                                            missingProperty:
                                                              'submitAmm',
                                                          },
                                                          message:
                                                            "must have required property '" +
                                                            'submitAmm' +
                                                            "'",
                                                        };
                                                        if (vErrors === null) {
                                                          vErrors = [err59];
                                                        } else {
                                                          vErrors.push(err59);
                                                        }
                                                        errors++;
                                                      }
                                                      for (const key25 in data2) {
                                                        if (
                                                          !(
                                                            key25 ===
                                                            'submitAmm'
                                                          )
                                                        ) {
                                                          const err60 = {
                                                            instancePath:
                                                              instancePath +
                                                              '/transaction',
                                                            schemaPath:
                                                              '#/properties/transaction/oneOf/24/additionalProperties',
                                                            keyword:
                                                              'additionalProperties',
                                                            params: {
                                                              additionalProperty:
                                                                key25,
                                                            },
                                                            message:
                                                              'must NOT have additional properties',
                                                          };
                                                          if (
                                                            vErrors === null
                                                          ) {
                                                            vErrors = [err60];
                                                          } else {
                                                            vErrors.push(err60);
                                                          }
                                                          errors++;
                                                        }
                                                      }
                                                    }
                                                    var _valid0 =
                                                      _errs57 === errors;
                                                    if (_valid0 && valid1) {
                                                      valid1 = false;
                                                      passing0 = [passing0, 24];
                                                    } else {
                                                      if (_valid0) {
                                                        valid1 = true;
                                                        passing0 = 24;
                                                      }
                                                      const _errs59 = errors;
                                                      if (
                                                        data2 &&
                                                        typeof data2 ==
                                                          'object' &&
                                                        !Array.isArray(data2)
                                                      ) {
                                                        if (
                                                          data2.amendAmm ===
                                                          undefined
                                                        ) {
                                                          const err61 = {
                                                            instancePath:
                                                              instancePath +
                                                              '/transaction',
                                                            schemaPath:
                                                              '#/properties/transaction/oneOf/25/required',
                                                            keyword: 'required',
                                                            params: {
                                                              missingProperty:
                                                                'amendAmm',
                                                            },
                                                            message:
                                                              "must have required property '" +
                                                              'amendAmm' +
                                                              "'",
                                                          };
                                                          if (
                                                            vErrors === null
                                                          ) {
                                                            vErrors = [err61];
                                                          } else {
                                                            vErrors.push(err61);
                                                          }
                                                          errors++;
                                                        }
                                                        for (const key26 in data2) {
                                                          if (
                                                            !(
                                                              key26 ===
                                                              'amendAmm'
                                                            )
                                                          ) {
                                                            const err62 = {
                                                              instancePath:
                                                                instancePath +
                                                                '/transaction',
                                                              schemaPath:
                                                                '#/properties/transaction/oneOf/25/additionalProperties',
                                                              keyword:
                                                                'additionalProperties',
                                                              params: {
                                                                additionalProperty:
                                                                  key26,
                                                              },
                                                              message:
                                                                'must NOT have additional properties',
                                                            };
                                                            if (
                                                              vErrors === null
                                                            ) {
                                                              vErrors = [err62];
                                                            } else {
                                                              vErrors.push(
                                                                err62
                                                              );
                                                            }
                                                            errors++;
                                                          }
                                                        }
                                                      }
                                                      var _valid0 =
                                                        _errs59 === errors;
                                                      if (_valid0 && valid1) {
                                                        valid1 = false;
                                                        passing0 = [
                                                          passing0,
                                                          25,
                                                        ];
                                                      } else {
                                                        if (_valid0) {
                                                          valid1 = true;
                                                          passing0 = 25;
                                                        }
                                                        const _errs61 = errors;
                                                        if (
                                                          data2 &&
                                                          typeof data2 ==
                                                            'object' &&
                                                          !Array.isArray(data2)
                                                        ) {
                                                          if (
                                                            data2.cancelAmm ===
                                                            undefined
                                                          ) {
                                                            const err63 = {
                                                              instancePath:
                                                                instancePath +
                                                                '/transaction',
                                                              schemaPath:
                                                                '#/properties/transaction/oneOf/26/required',
                                                              keyword:
                                                                'required',
                                                              params: {
                                                                missingProperty:
                                                                  'cancelAmm',
                                                              },
                                                              message:
                                                                "must have required property '" +
                                                                'cancelAmm' +
                                                                "'",
                                                            };
                                                            if (
                                                              vErrors === null
                                                            ) {
                                                              vErrors = [err63];
                                                            } else {
                                                              vErrors.push(
                                                                err63
                                                              );
                                                            }
                                                            errors++;
                                                          }
                                                          for (const key27 in data2) {
                                                            if (
                                                              !(
                                                                key27 ===
                                                                'cancelAmm'
                                                              )
                                                            ) {
                                                              const err64 = {
                                                                instancePath:
                                                                  instancePath +
                                                                  '/transaction',
                                                                schemaPath:
                                                                  '#/properties/transaction/oneOf/26/additionalProperties',
                                                                keyword:
                                                                  'additionalProperties',
                                                                params: {
                                                                  additionalProperty:
                                                                    key27,
                                                                },
                                                                message:
                                                                  'must NOT have additional properties',
                                                              };
                                                              if (
                                                                vErrors === null
                                                              ) {
                                                                vErrors = [
                                                                  err64,
                                                                ];
                                                              } else {
                                                                vErrors.push(
                                                                  err64
                                                                );
                                                              }
                                                              errors++;
                                                            }
                                                          }
                                                        }
                                                        var _valid0 =
                                                          _errs61 === errors;
                                                        if (_valid0 && valid1) {
                                                          valid1 = false;
                                                          passing0 = [
                                                            passing0,
                                                            26,
                                                          ];
                                                        } else {
                                                          if (_valid0) {
                                                            valid1 = true;
                                                            passing0 = 26;
                                                          }
                                                          const _errs63 =
                                                            errors;
                                                          if (
                                                            data2 &&
                                                            typeof data2 ==
                                                              'object' &&
                                                            !Array.isArray(
                                                              data2
                                                            )
                                                          ) {
                                                            if (
                                                              data2.nodeVote ===
                                                              undefined
                                                            ) {
                                                              const err65 = {
                                                                instancePath:
                                                                  instancePath +
                                                                  '/transaction',
                                                                schemaPath:
                                                                  '#/properties/transaction/oneOf/27/required',
                                                                keyword:
                                                                  'required',
                                                                params: {
                                                                  missingProperty:
                                                                    'nodeVote',
                                                                },
                                                                message:
                                                                  "must have required property '" +
                                                                  'nodeVote' +
                                                                  "'",
                                                              };
                                                              if (
                                                                vErrors === null
                                                              ) {
                                                                vErrors = [
                                                                  err65,
                                                                ];
                                                              } else {
                                                                vErrors.push(
                                                                  err65
                                                                );
                                                              }
                                                              errors++;
                                                            }
                                                            for (const key28 in data2) {
                                                              if (
                                                                !(
                                                                  key28 ===
                                                                  'nodeVote'
                                                                )
                                                              ) {
                                                                const err66 = {
                                                                  instancePath:
                                                                    instancePath +
                                                                    '/transaction',
                                                                  schemaPath:
                                                                    '#/properties/transaction/oneOf/27/additionalProperties',
                                                                  keyword:
                                                                    'additionalProperties',
                                                                  params: {
                                                                    additionalProperty:
                                                                      key28,
                                                                  },
                                                                  message:
                                                                    'must NOT have additional properties',
                                                                };
                                                                if (
                                                                  vErrors ===
                                                                  null
                                                                ) {
                                                                  vErrors = [
                                                                    err66,
                                                                  ];
                                                                } else {
                                                                  vErrors.push(
                                                                    err66
                                                                  );
                                                                }
                                                                errors++;
                                                              }
                                                            }
                                                          }
                                                          var _valid0 =
                                                            _errs63 === errors;
                                                          if (
                                                            _valid0 &&
                                                            valid1
                                                          ) {
                                                            valid1 = false;
                                                            passing0 = [
                                                              passing0,
                                                              27,
                                                            ];
                                                          } else {
                                                            if (_valid0) {
                                                              valid1 = true;
                                                              passing0 = 27;
                                                            }
                                                            const _errs65 =
                                                              errors;
                                                            if (
                                                              data2 &&
                                                              typeof data2 ==
                                                                'object' &&
                                                              !Array.isArray(
                                                                data2
                                                              )
                                                            ) {
                                                              if (
                                                                data2.nodeSignature ===
                                                                undefined
                                                              ) {
                                                                const err67 = {
                                                                  instancePath:
                                                                    instancePath +
                                                                    '/transaction',
                                                                  schemaPath:
                                                                    '#/properties/transaction/oneOf/28/required',
                                                                  keyword:
                                                                    'required',
                                                                  params: {
                                                                    missingProperty:
                                                                      'nodeSignature',
                                                                  },
                                                                  message:
                                                                    "must have required property '" +
                                                                    'nodeSignature' +
                                                                    "'",
                                                                };
                                                                if (
                                                                  vErrors ===
                                                                  null
                                                                ) {
                                                                  vErrors = [
                                                                    err67,
                                                                  ];
                                                                } else {
                                                                  vErrors.push(
                                                                    err67
                                                                  );
                                                                }
                                                                errors++;
                                                              }
                                                              for (const key29 in data2) {
                                                                if (
                                                                  !(
                                                                    key29 ===
                                                                    'nodeSignature'
                                                                  )
                                                                ) {
                                                                  const err68 =
                                                                    {
                                                                      instancePath:
                                                                        instancePath +
                                                                        '/transaction',
                                                                      schemaPath:
                                                                        '#/properties/transaction/oneOf/28/additionalProperties',
                                                                      keyword:
                                                                        'additionalProperties',
                                                                      params: {
                                                                        additionalProperty:
                                                                          key29,
                                                                      },
                                                                      message:
                                                                        'must NOT have additional properties',
                                                                    };
                                                                  if (
                                                                    vErrors ===
                                                                    null
                                                                  ) {
                                                                    vErrors = [
                                                                      err68,
                                                                    ];
                                                                  } else {
                                                                    vErrors.push(
                                                                      err68
                                                                    );
                                                                  }
                                                                  errors++;
                                                                }
                                                              }
                                                            }
                                                            var _valid0 =
                                                              _errs65 ===
                                                              errors;
                                                            if (
                                                              _valid0 &&
                                                              valid1
                                                            ) {
                                                              valid1 = false;
                                                              passing0 = [
                                                                passing0,
                                                                28,
                                                              ];
                                                            } else {
                                                              if (_valid0) {
                                                                valid1 = true;
                                                                passing0 = 28;
                                                              }
                                                              const _errs67 =
                                                                errors;
                                                              if (
                                                                data2 &&
                                                                typeof data2 ==
                                                                  'object' &&
                                                                !Array.isArray(
                                                                  data2
                                                                )
                                                              ) {
                                                                if (
                                                                  data2.chainEvent ===
                                                                  undefined
                                                                ) {
                                                                  const err69 =
                                                                    {
                                                                      instancePath:
                                                                        instancePath +
                                                                        '/transaction',
                                                                      schemaPath:
                                                                        '#/properties/transaction/oneOf/29/required',
                                                                      keyword:
                                                                        'required',
                                                                      params: {
                                                                        missingProperty:
                                                                          'chainEvent',
                                                                      },
                                                                      message:
                                                                        "must have required property '" +
                                                                        'chainEvent' +
                                                                        "'",
                                                                    };
                                                                  if (
                                                                    vErrors ===
                                                                    null
                                                                  ) {
                                                                    vErrors = [
                                                                      err69,
                                                                    ];
                                                                  } else {
                                                                    vErrors.push(
                                                                      err69
                                                                    );
                                                                  }
                                                                  errors++;
                                                                }
                                                                for (const key30 in data2) {
                                                                  if (
                                                                    !(
                                                                      key30 ===
                                                                      'chainEvent'
                                                                    )
                                                                  ) {
                                                                    const err70 =
                                                                      {
                                                                        instancePath:
                                                                          instancePath +
                                                                          '/transaction',
                                                                        schemaPath:
                                                                          '#/properties/transaction/oneOf/29/additionalProperties',
                                                                        keyword:
                                                                          'additionalProperties',
                                                                        params:
                                                                          {
                                                                            additionalProperty:
                                                                              key30,
                                                                          },
                                                                        message:
                                                                          'must NOT have additional properties',
                                                                      };
                                                                    if (
                                                                      vErrors ===
                                                                      null
                                                                    ) {
                                                                      vErrors =
                                                                        [err70];
                                                                    } else {
                                                                      vErrors.push(
                                                                        err70
                                                                      );
                                                                    }
                                                                    errors++;
                                                                  }
                                                                }
                                                              }
                                                              var _valid0 =
                                                                _errs67 ===
                                                                errors;
                                                              if (
                                                                _valid0 &&
                                                                valid1
                                                              ) {
                                                                valid1 = false;
                                                                passing0 = [
                                                                  passing0,
                                                                  29,
                                                                ];
                                                              } else {
                                                                if (_valid0) {
                                                                  valid1 = true;
                                                                  passing0 = 29;
                                                                }
                                                                const _errs69 =
                                                                  errors;
                                                                if (
                                                                  data2 &&
                                                                  typeof data2 ==
                                                                    'object' &&
                                                                  !Array.isArray(
                                                                    data2
                                                                  )
                                                                ) {
                                                                  if (
                                                                    data2.keyRotateSubmission ===
                                                                    undefined
                                                                  ) {
                                                                    const err71 =
                                                                      {
                                                                        instancePath:
                                                                          instancePath +
                                                                          '/transaction',
                                                                        schemaPath:
                                                                          '#/properties/transaction/oneOf/30/required',
                                                                        keyword:
                                                                          'required',
                                                                        params:
                                                                          {
                                                                            missingProperty:
                                                                              'keyRotateSubmission',
                                                                          },
                                                                        message:
                                                                          "must have required property '" +
                                                                          'keyRotateSubmission' +
                                                                          "'",
                                                                      };
                                                                    if (
                                                                      vErrors ===
                                                                      null
                                                                    ) {
                                                                      vErrors =
                                                                        [err71];
                                                                    } else {
                                                                      vErrors.push(
                                                                        err71
                                                                      );
                                                                    }
                                                                    errors++;
                                                                  }
                                                                  for (const key31 in data2) {
                                                                    if (
                                                                      !(
                                                                        key31 ===
                                                                        'keyRotateSubmission'
                                                                      )
                                                                    ) {
                                                                      const err72 =
                                                                        {
                                                                          instancePath:
                                                                            instancePath +
                                                                            '/transaction',
                                                                          schemaPath:
                                                                            '#/properties/transaction/oneOf/30/additionalProperties',
                                                                          keyword:
                                                                            'additionalProperties',
                                                                          params:
                                                                            {
                                                                              additionalProperty:
                                                                                key31,
                                                                            },
                                                                          message:
                                                                            'must NOT have additional properties',
                                                                        };
                                                                      if (
                                                                        vErrors ===
                                                                        null
                                                                      ) {
                                                                        vErrors =
                                                                          [
                                                                            err72,
                                                                          ];
                                                                      } else {
                                                                        vErrors.push(
                                                                          err72
                                                                        );
                                                                      }
                                                                      errors++;
                                                                    }
                                                                  }
                                                                }
                                                                var _valid0 =
                                                                  _errs69 ===
                                                                  errors;
                                                                if (
                                                                  _valid0 &&
                                                                  valid1
                                                                ) {
                                                                  valid1 = false;
                                                                  passing0 = [
                                                                    passing0,
                                                                    30,
                                                                  ];
                                                                } else {
                                                                  if (_valid0) {
                                                                    valid1 = true;
                                                                    passing0 = 30;
                                                                  }
                                                                  const _errs71 =
                                                                    errors;
                                                                  if (
                                                                    data2 &&
                                                                    typeof data2 ==
                                                                      'object' &&
                                                                    !Array.isArray(
                                                                      data2
                                                                    )
                                                                  ) {
                                                                    if (
                                                                      data2.stateVariableProposal ===
                                                                      undefined
                                                                    ) {
                                                                      const err73 =
                                                                        {
                                                                          instancePath:
                                                                            instancePath +
                                                                            '/transaction',
                                                                          schemaPath:
                                                                            '#/properties/transaction/oneOf/31/required',
                                                                          keyword:
                                                                            'required',
                                                                          params:
                                                                            {
                                                                              missingProperty:
                                                                                'stateVariableProposal',
                                                                            },
                                                                          message:
                                                                            "must have required property '" +
                                                                            'stateVariableProposal' +
                                                                            "'",
                                                                        };
                                                                      if (
                                                                        vErrors ===
                                                                        null
                                                                      ) {
                                                                        vErrors =
                                                                          [
                                                                            err73,
                                                                          ];
                                                                      } else {
                                                                        vErrors.push(
                                                                          err73
                                                                        );
                                                                      }
                                                                      errors++;
                                                                    }
                                                                    for (const key32 in data2) {
                                                                      if (
                                                                        !(
                                                                          key32 ===
                                                                          'stateVariableProposal'
                                                                        )
                                                                      ) {
                                                                        const err74 =
                                                                          {
                                                                            instancePath:
                                                                              instancePath +
                                                                              '/transaction',
                                                                            schemaPath:
                                                                              '#/properties/transaction/oneOf/31/additionalProperties',
                                                                            keyword:
                                                                              'additionalProperties',
                                                                            params:
                                                                              {
                                                                                additionalProperty:
                                                                                  key32,
                                                                              },
                                                                            message:
                                                                              'must NOT have additional properties',
                                                                          };
                                                                        if (
                                                                          vErrors ===
                                                                          null
                                                                        ) {
                                                                          vErrors =
                                                                            [
                                                                              err74,
                                                                            ];
                                                                        } else {
                                                                          vErrors.push(
                                                                            err74
                                                                          );
                                                                        }
                                                                        errors++;
                                                                      }
                                                                    }
                                                                  }
                                                                  var _valid0 =
                                                                    _errs71 ===
                                                                    errors;
                                                                  if (
                                                                    _valid0 &&
                                                                    valid1
                                                                  ) {
                                                                    valid1 = false;
                                                                    passing0 = [
                                                                      passing0,
                                                                      31,
                                                                    ];
                                                                  } else {
                                                                    if (
                                                                      _valid0
                                                                    ) {
                                                                      valid1 = true;
                                                                      passing0 = 31;
                                                                    }
                                                                    const _errs73 =
                                                                      errors;
                                                                    if (
                                                                      data2 &&
                                                                      typeof data2 ==
                                                                        'object' &&
                                                                      !Array.isArray(
                                                                        data2
                                                                      )
                                                                    ) {
                                                                      if (
                                                                        data2.validatorHeartbeat ===
                                                                        undefined
                                                                      ) {
                                                                        const err75 =
                                                                          {
                                                                            instancePath:
                                                                              instancePath +
                                                                              '/transaction',
                                                                            schemaPath:
                                                                              '#/properties/transaction/oneOf/32/required',
                                                                            keyword:
                                                                              'required',
                                                                            params:
                                                                              {
                                                                                missingProperty:
                                                                                  'validatorHeartbeat',
                                                                              },
                                                                            message:
                                                                              "must have required property '" +
                                                                              'validatorHeartbeat' +
                                                                              "'",
                                                                          };
                                                                        if (
                                                                          vErrors ===
                                                                          null
                                                                        ) {
                                                                          vErrors =
                                                                            [
                                                                              err75,
                                                                            ];
                                                                        } else {
                                                                          vErrors.push(
                                                                            err75
                                                                          );
                                                                        }
                                                                        errors++;
                                                                      }
                                                                      for (const key33 in data2) {
                                                                        if (
                                                                          !(
                                                                            key33 ===
                                                                            'validatorHeartbeat'
                                                                          )
                                                                        ) {
                                                                          const err76 =
                                                                            {
                                                                              instancePath:
                                                                                instancePath +
                                                                                '/transaction',
                                                                              schemaPath:
                                                                                '#/properties/transaction/oneOf/32/additionalProperties',
                                                                              keyword:
                                                                                'additionalProperties',
                                                                              params:
                                                                                {
                                                                                  additionalProperty:
                                                                                    key33,
                                                                                },
                                                                              message:
                                                                                'must NOT have additional properties',
                                                                            };
                                                                          if (
                                                                            vErrors ===
                                                                            null
                                                                          ) {
                                                                            vErrors =
                                                                              [
                                                                                err76,
                                                                              ];
                                                                          } else {
                                                                            vErrors.push(
                                                                              err76
                                                                            );
                                                                          }
                                                                          errors++;
                                                                        }
                                                                      }
                                                                    }
                                                                    var _valid0 =
                                                                      _errs73 ===
                                                                      errors;
                                                                    if (
                                                                      _valid0 &&
                                                                      valid1
                                                                    ) {
                                                                      valid1 = false;
                                                                      passing0 =
                                                                        [
                                                                          passing0,
                                                                          32,
                                                                        ];
                                                                    } else {
                                                                      if (
                                                                        _valid0
                                                                      ) {
                                                                        valid1 = true;
                                                                        passing0 = 32;
                                                                      }
                                                                      const _errs75 =
                                                                        errors;
                                                                      if (
                                                                        data2 &&
                                                                        typeof data2 ==
                                                                          'object' &&
                                                                        !Array.isArray(
                                                                          data2
                                                                        )
                                                                      ) {
                                                                        if (
                                                                          data2.ethereumKeyRotateSubmission ===
                                                                          undefined
                                                                        ) {
                                                                          const err77 =
                                                                            {
                                                                              instancePath:
                                                                                instancePath +
                                                                                '/transaction',
                                                                              schemaPath:
                                                                                '#/properties/transaction/oneOf/33/required',
                                                                              keyword:
                                                                                'required',
                                                                              params:
                                                                                {
                                                                                  missingProperty:
                                                                                    'ethereumKeyRotateSubmission',
                                                                                },
                                                                              message:
                                                                                "must have required property '" +
                                                                                'ethereumKeyRotateSubmission' +
                                                                                "'",
                                                                            };
                                                                          if (
                                                                            vErrors ===
                                                                            null
                                                                          ) {
                                                                            vErrors =
                                                                              [
                                                                                err77,
                                                                              ];
                                                                          } else {
                                                                            vErrors.push(
                                                                              err77
                                                                            );
                                                                          }
                                                                          errors++;
                                                                        }
                                                                        for (const key34 in data2) {
                                                                          if (
                                                                            !(
                                                                              key34 ===
                                                                              'ethereumKeyRotateSubmission'
                                                                            )
                                                                          ) {
                                                                            const err78 =
                                                                              {
                                                                                instancePath:
                                                                                  instancePath +
                                                                                  '/transaction',
                                                                                schemaPath:
                                                                                  '#/properties/transaction/oneOf/33/additionalProperties',
                                                                                keyword:
                                                                                  'additionalProperties',
                                                                                params:
                                                                                  {
                                                                                    additionalProperty:
                                                                                      key34,
                                                                                  },
                                                                                message:
                                                                                  'must NOT have additional properties',
                                                                              };
                                                                            if (
                                                                              vErrors ===
                                                                              null
                                                                            ) {
                                                                              vErrors =
                                                                                [
                                                                                  err78,
                                                                                ];
                                                                            } else {
                                                                              vErrors.push(
                                                                                err78
                                                                              );
                                                                            }
                                                                            errors++;
                                                                          }
                                                                        }
                                                                      }
                                                                      var _valid0 =
                                                                        _errs75 ===
                                                                        errors;
                                                                      if (
                                                                        _valid0 &&
                                                                        valid1
                                                                      ) {
                                                                        valid1 = false;
                                                                        passing0 =
                                                                          [
                                                                            passing0,
                                                                            33,
                                                                          ];
                                                                      } else {
                                                                        if (
                                                                          _valid0
                                                                        ) {
                                                                          valid1 = true;
                                                                          passing0 = 33;
                                                                        }
                                                                        const _errs77 =
                                                                          errors;
                                                                        if (
                                                                          data2 &&
                                                                          typeof data2 ==
                                                                            'object' &&
                                                                          !Array.isArray(
                                                                            data2
                                                                          )
                                                                        ) {
                                                                          if (
                                                                            data2.protocolUpgradeProposal ===
                                                                            undefined
                                                                          ) {
                                                                            const err79 =
                                                                              {
                                                                                instancePath:
                                                                                  instancePath +
                                                                                  '/transaction',
                                                                                schemaPath:
                                                                                  '#/properties/transaction/oneOf/34/required',
                                                                                keyword:
                                                                                  'required',
                                                                                params:
                                                                                  {
                                                                                    missingProperty:
                                                                                      'protocolUpgradeProposal',
                                                                                  },
                                                                                message:
                                                                                  "must have required property '" +
                                                                                  'protocolUpgradeProposal' +
                                                                                  "'",
                                                                              };
                                                                            if (
                                                                              vErrors ===
                                                                              null
                                                                            ) {
                                                                              vErrors =
                                                                                [
                                                                                  err79,
                                                                                ];
                                                                            } else {
                                                                              vErrors.push(
                                                                                err79
                                                                              );
                                                                            }
                                                                            errors++;
                                                                          }
                                                                          for (const key35 in data2) {
                                                                            if (
                                                                              !(
                                                                                key35 ===
                                                                                'protocolUpgradeProposal'
                                                                              )
                                                                            ) {
                                                                              const err80 =
                                                                                {
                                                                                  instancePath:
                                                                                    instancePath +
                                                                                    '/transaction',
                                                                                  schemaPath:
                                                                                    '#/properties/transaction/oneOf/34/additionalProperties',
                                                                                  keyword:
                                                                                    'additionalProperties',
                                                                                  params:
                                                                                    {
                                                                                      additionalProperty:
                                                                                        key35,
                                                                                    },
                                                                                  message:
                                                                                    'must NOT have additional properties',
                                                                                };
                                                                              if (
                                                                                vErrors ===
                                                                                null
                                                                              ) {
                                                                                vErrors =
                                                                                  [
                                                                                    err80,
                                                                                  ];
                                                                              } else {
                                                                                vErrors.push(
                                                                                  err80
                                                                                );
                                                                              }
                                                                              errors++;
                                                                            }
                                                                          }
                                                                        }
                                                                        var _valid0 =
                                                                          _errs77 ===
                                                                          errors;
                                                                        if (
                                                                          _valid0 &&
                                                                          valid1
                                                                        ) {
                                                                          valid1 = false;
                                                                          passing0 =
                                                                            [
                                                                              passing0,
                                                                              34,
                                                                            ];
                                                                        } else {
                                                                          if (
                                                                            _valid0
                                                                          ) {
                                                                            valid1 = true;
                                                                            passing0 = 34;
                                                                          }
                                                                          const _errs79 =
                                                                            errors;
                                                                          if (
                                                                            data2 &&
                                                                            typeof data2 ==
                                                                              'object' &&
                                                                            !Array.isArray(
                                                                              data2
                                                                            )
                                                                          ) {
                                                                            if (
                                                                              data2.issueSignatures ===
                                                                              undefined
                                                                            ) {
                                                                              const err81 =
                                                                                {
                                                                                  instancePath:
                                                                                    instancePath +
                                                                                    '/transaction',
                                                                                  schemaPath:
                                                                                    '#/properties/transaction/oneOf/35/required',
                                                                                  keyword:
                                                                                    'required',
                                                                                  params:
                                                                                    {
                                                                                      missingProperty:
                                                                                        'issueSignatures',
                                                                                    },
                                                                                  message:
                                                                                    "must have required property '" +
                                                                                    'issueSignatures' +
                                                                                    "'",
                                                                                };
                                                                              if (
                                                                                vErrors ===
                                                                                null
                                                                              ) {
                                                                                vErrors =
                                                                                  [
                                                                                    err81,
                                                                                  ];
                                                                              } else {
                                                                                vErrors.push(
                                                                                  err81
                                                                                );
                                                                              }
                                                                              errors++;
                                                                            }
                                                                            for (const key36 in data2) {
                                                                              if (
                                                                                !(
                                                                                  key36 ===
                                                                                  'issueSignatures'
                                                                                )
                                                                              ) {
                                                                                const err82 =
                                                                                  {
                                                                                    instancePath:
                                                                                      instancePath +
                                                                                      '/transaction',
                                                                                    schemaPath:
                                                                                      '#/properties/transaction/oneOf/35/additionalProperties',
                                                                                    keyword:
                                                                                      'additionalProperties',
                                                                                    params:
                                                                                      {
                                                                                        additionalProperty:
                                                                                          key36,
                                                                                      },
                                                                                    message:
                                                                                      'must NOT have additional properties',
                                                                                  };
                                                                                if (
                                                                                  vErrors ===
                                                                                  null
                                                                                ) {
                                                                                  vErrors =
                                                                                    [
                                                                                      err82,
                                                                                    ];
                                                                                } else {
                                                                                  vErrors.push(
                                                                                    err82
                                                                                  );
                                                                                }
                                                                                errors++;
                                                                              }
                                                                            }
                                                                          }
                                                                          var _valid0 =
                                                                            _errs79 ===
                                                                            errors;
                                                                          if (
                                                                            _valid0 &&
                                                                            valid1
                                                                          ) {
                                                                            valid1 = false;
                                                                            passing0 =
                                                                              [
                                                                                passing0,
                                                                                35,
                                                                              ];
                                                                          } else {
                                                                            if (
                                                                              _valid0
                                                                            ) {
                                                                              valid1 = true;
                                                                              passing0 = 35;
                                                                            }
                                                                            const _errs81 =
                                                                              errors;
                                                                            if (
                                                                              data2 &&
                                                                              typeof data2 ==
                                                                                'object' &&
                                                                              !Array.isArray(
                                                                                data2
                                                                              )
                                                                            ) {
                                                                              if (
                                                                                data2.oracleDataSubmission ===
                                                                                undefined
                                                                              ) {
                                                                                const err83 =
                                                                                  {
                                                                                    instancePath:
                                                                                      instancePath +
                                                                                      '/transaction',
                                                                                    schemaPath:
                                                                                      '#/properties/transaction/oneOf/36/required',
                                                                                    keyword:
                                                                                      'required',
                                                                                    params:
                                                                                      {
                                                                                        missingProperty:
                                                                                          'oracleDataSubmission',
                                                                                      },
                                                                                    message:
                                                                                      "must have required property '" +
                                                                                      'oracleDataSubmission' +
                                                                                      "'",
                                                                                  };
                                                                                if (
                                                                                  vErrors ===
                                                                                  null
                                                                                ) {
                                                                                  vErrors =
                                                                                    [
                                                                                      err83,
                                                                                    ];
                                                                                } else {
                                                                                  vErrors.push(
                                                                                    err83
                                                                                  );
                                                                                }
                                                                                errors++;
                                                                              }
                                                                              for (const key37 in data2) {
                                                                                if (
                                                                                  !(
                                                                                    key37 ===
                                                                                    'oracleDataSubmission'
                                                                                  )
                                                                                ) {
                                                                                  const err84 =
                                                                                    {
                                                                                      instancePath:
                                                                                        instancePath +
                                                                                        '/transaction',
                                                                                      schemaPath:
                                                                                        '#/properties/transaction/oneOf/36/additionalProperties',
                                                                                      keyword:
                                                                                        'additionalProperties',
                                                                                      params:
                                                                                        {
                                                                                          additionalProperty:
                                                                                            key37,
                                                                                        },
                                                                                      message:
                                                                                        'must NOT have additional properties',
                                                                                    };
                                                                                  if (
                                                                                    vErrors ===
                                                                                    null
                                                                                  ) {
                                                                                    vErrors =
                                                                                      [
                                                                                        err84,
                                                                                      ];
                                                                                  } else {
                                                                                    vErrors.push(
                                                                                      err84
                                                                                    );
                                                                                  }
                                                                                  errors++;
                                                                                }
                                                                              }
                                                                            }
                                                                            var _valid0 =
                                                                              _errs81 ===
                                                                              errors;
                                                                            if (
                                                                              _valid0 &&
                                                                              valid1
                                                                            ) {
                                                                              valid1 = false;
                                                                              passing0 =
                                                                                [
                                                                                  passing0,
                                                                                  36,
                                                                                ];
                                                                            } else {
                                                                              if (
                                                                                _valid0
                                                                              ) {
                                                                                valid1 = true;
                                                                                passing0 = 36;
                                                                              }
                                                                              const _errs83 =
                                                                                errors;
                                                                              if (
                                                                                data2 &&
                                                                                typeof data2 ==
                                                                                  'object' &&
                                                                                !Array.isArray(
                                                                                  data2
                                                                                )
                                                                              ) {
                                                                                if (
                                                                                  data2.delayedTransactionsWrapper ===
                                                                                  undefined
                                                                                ) {
                                                                                  const err85 =
                                                                                    {
                                                                                      instancePath:
                                                                                        instancePath +
                                                                                        '/transaction',
                                                                                      schemaPath:
                                                                                        '#/properties/transaction/oneOf/37/required',
                                                                                      keyword:
                                                                                        'required',
                                                                                      params:
                                                                                        {
                                                                                          missingProperty:
                                                                                            'delayedTransactionsWrapper',
                                                                                        },
                                                                                      message:
                                                                                        "must have required property '" +
                                                                                        'delayedTransactionsWrapper' +
                                                                                        "'",
                                                                                    };
                                                                                  if (
                                                                                    vErrors ===
                                                                                    null
                                                                                  ) {
                                                                                    vErrors =
                                                                                      [
                                                                                        err85,
                                                                                      ];
                                                                                  } else {
                                                                                    vErrors.push(
                                                                                      err85
                                                                                    );
                                                                                  }
                                                                                  errors++;
                                                                                }
                                                                                for (const key38 in data2) {
                                                                                  if (
                                                                                    !(
                                                                                      key38 ===
                                                                                      'delayedTransactionsWrapper'
                                                                                    )
                                                                                  ) {
                                                                                    const err86 =
                                                                                      {
                                                                                        instancePath:
                                                                                          instancePath +
                                                                                          '/transaction',
                                                                                        schemaPath:
                                                                                          '#/properties/transaction/oneOf/37/additionalProperties',
                                                                                        keyword:
                                                                                          'additionalProperties',
                                                                                        params:
                                                                                          {
                                                                                            additionalProperty:
                                                                                              key38,
                                                                                          },
                                                                                        message:
                                                                                          'must NOT have additional properties',
                                                                                      };
                                                                                    if (
                                                                                      vErrors ===
                                                                                      null
                                                                                    ) {
                                                                                      vErrors =
                                                                                        [
                                                                                          err86,
                                                                                        ];
                                                                                    } else {
                                                                                      vErrors.push(
                                                                                        err86
                                                                                      );
                                                                                    }
                                                                                    errors++;
                                                                                  }
                                                                                }
                                                                              }
                                                                              var _valid0 =
                                                                                _errs83 ===
                                                                                errors;
                                                                              if (
                                                                                _valid0 &&
                                                                                valid1
                                                                              ) {
                                                                                valid1 = false;
                                                                                passing0 =
                                                                                  [
                                                                                    passing0,
                                                                                    37,
                                                                                  ];
                                                                              } else {
                                                                                if (
                                                                                  _valid0
                                                                                ) {
                                                                                  valid1 = true;
                                                                                  passing0 = 37;
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (!valid1) {
        const err87 = {
          instancePath: instancePath + '/transaction',
          schemaPath: '#/properties/transaction/oneOf',
          keyword: 'oneOf',
          params: { passingSchemas: passing0 },
          message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
          vErrors = [err87];
        } else {
          vErrors.push(err87);
        }
        errors++;
      } else {
        errors = _errs8;
        if (vErrors !== null) {
          if (_errs8) {
            vErrors.length = _errs8;
          } else {
            vErrors = null;
          }
        }
      }
      if (errors > 0) {
        const emErrs2 = [];
        for (const err88 of vErrors) {
          if (
            err88.keyword !== 'errorMessage' &&
            !err88.emUsed &&
            (err88.instancePath === instancePath + '/transaction' ||
              (err88.instancePath.indexOf(instancePath + '/transaction') ===
                0 &&
                err88.instancePath[instancePath + '/transaction'.length] ===
                  '/')) &&
            err88.schemaPath.indexOf('#/properties/transaction') === 0 &&
            err88.schemaPath['#/properties/transaction'.length] === '/'
          ) {
            emErrs2.push(err88);
            err88.emUsed = true;
          }
        }
        if (emErrs2.length) {
          const err89 = {
            instancePath: instancePath + '/transaction',
            schemaPath: '#/properties/transaction/errorMessage',
            keyword: 'errorMessage',
            params: { errors: emErrs2 },
            message: 'Unsupported transaction type',
          };
          if (vErrors === null) {
            vErrors = [err89];
          } else {
            vErrors.push(err89);
          }
          errors++;
        }
        const emErrs3 = [];
        for (const err90 of vErrors) {
          if (!err90.emUsed) {
            emErrs3.push(err90);
          }
        }
        vErrors = emErrs3;
        errors = emErrs3.length;
      }
    }
  } else {
    const err91 = {
      instancePath,
      schemaPath: '#/type',
      keyword: 'type',
      params: { type: 'object' },
      message: 'must be object',
    };
    if (vErrors === null) {
      vErrors = [err91];
    } else {
      vErrors.push(err91);
    }
    errors++;
  }
  if (errors > 0) {
    const emErrs4 = [];
    for (const err92 of vErrors) {
      if (
        err92.keyword !== 'errorMessage' &&
        !err92.emUsed &&
        (err92.instancePath === instancePath ||
          (err92.instancePath.indexOf(instancePath) === 0 &&
            err92.instancePath[instancePath.length] === '/')) &&
        err92.schemaPath.indexOf('#') === 0 &&
        err92.schemaPath['#'.length] === '/'
      ) {
        emErrs4.push(err92);
        err92.emUsed = true;
      }
    }
    if (emErrs4.length) {
      const err93 = {
        instancePath,
        schemaPath: '#/errorMessage',
        keyword: 'errorMessage',
        params: { errors: emErrs4 },
        message:
          '`client.send_transaction` must only be given `publicKey`, `transaction` and `origin`',
      };
      if (vErrors === null) {
        vErrors = [err93];
      } else {
        vErrors.push(err93);
      }
      errors++;
    }
    const emErrs5 = [];
    for (const err94 of vErrors) {
      if (!err94.emUsed) {
        emErrs5.push(err94);
      }
    }
    vErrors = emErrs5;
    errors = emErrs5.length;
  }
  validate14.errors = vErrors;
  return errors === 0;
}

export { validate14 as default, validate };
