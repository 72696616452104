export const IconLoading = ({ size = 16 }: { size: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      className="stroke-current"
    >
      <path
        d="M20.5 12.5C20.5 11.3838 20.2801 10.2785 19.853 9.24719C19.4258 8.21592 18.7997 7.27889 18.0104 6.48959C17.2211 5.7003 16.2841 5.07419 15.2528 4.64702C14.2215 4.21986 13.1162 4 12 4C10.8838 4 9.77846 4.21986 8.74719 4.64702C7.71592 5.07419 6.77889 5.70029 5.98959 6.48959C5.20029 7.27889 4.57419 8.21592 4.14702 9.24719C3.71986 10.2785 3.5 11.3838 3.5 12.5C3.5 13.6162 3.71986 14.7215 4.14702 15.7528C4.57419 16.7841 5.20029 17.7211 5.98959 18.5104C6.77889 19.2997 7.71592 19.9258 8.74719 20.353C9.77846 20.7801 10.8838 21 12 21C13.1162 21 14.2215 20.7801 15.2528 20.353C16.2841 19.9258 17.2211 19.2997 18.0104 18.5104C18.7997 17.7211 19.4258 16.7841 19.853 15.7528C20.2801 14.7215 20.5 13.6162 20.5 12.5L20.5 12.5Z"
        strokeOpacity="0.32"
      />
      <path
        d="M9.37336 20.584C10.6491 20.9985 12.0048 21.1052 13.3297 20.8954C14.6546 20.6855 15.911 20.1651 16.9962 19.3766C18.0814 18.5882 18.9646 17.5541 19.5736 16.3589C20.1825 15.1637 20.5 13.8414 20.5 12.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
