export const IconEthereum = ({ size = 16 }: { size: number }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 50 50">
      <path d="M25.3999 0.160156L40.1999 24.6102L25.4499 18.3102L25.3999 0.160156Z" />
      <path d="M10.24 24.6102L25 0.160156L25.05 18.3102L10.24 24.6102Z" />
      <path d="M25 34.1305L10.24 25.1105L25.05 18.8105L25 34.1305Z" />
      <path d="M40.1999 25.1105L25.4499 18.8105L25.3999 34.1305L40.1999 25.1105Z" />
      <path d="M25.3999 37.23L40.1999 28.46L25.3999 49.31V37.23Z" />
      <path d="M25 37.23L10.24 28.46L25 49.31V37.23Z" />
    </svg>
  );
};
