/* eslint-disable */
/// Autogenerated by 'scripts/compile-ajv-schema.js' target 'schemas'
const validate = validate14;
const schema16 = {
  type: 'object',
  required: ['publicKey', 'transaction', 'sendingMode'],
  additionalProperties: false,
  errorMessage:
    '`client.send_transaction` must only be given `publicKey`, `transaction` and `sendingMode`',
  properties: {
    publicKey: {
      type: 'string',
      pattern: '^[0-9a-z]{64}$',
      errorMessage: '`publicKey` must be a 64 character hex encoded publicKey',
    },
    sendingMode: {
      enum: ['TYPE_ASYNC', 1, 'TYPE_SYNC', 2, 'TYPE_COMMIT', 3],
      errorMessage:
        'Only `TYPE_ASYNC` (1), `TYPE_SYNC` (2) and `TYPE_COMMIT` (3) are valid sending modes. The sendingMode must be the string name or numerical enum',
    },
    transaction: {
      type: 'object',
      errorMessage: 'Unsupported transaction type',
      oneOf: [
        {
          additionalProperties: false,
          required: ['orderSubmission'],
          properties: { orderSubmission: {} },
        },
        {
          additionalProperties: false,
          required: ['orderCancellation'],
          properties: { orderCancellation: {} },
        },
        {
          additionalProperties: false,
          required: ['orderAmendment'],
          properties: { orderAmendment: {} },
        },
        {
          additionalProperties: false,
          required: ['withdrawSubmission'],
          properties: { withdrawSubmission: {} },
        },
        {
          additionalProperties: false,
          required: ['proposalSubmission'],
          properties: { proposalSubmission: {} },
        },
        {
          additionalProperties: false,
          required: ['voteSubmission'],
          properties: { voteSubmission: {} },
        },
        {
          additionalProperties: false,
          required: ['liquidityProvisionSubmission'],
          properties: { liquidityProvisionSubmission: {} },
        },
        {
          additionalProperties: false,
          required: ['delegateSubmission'],
          properties: { delegateSubmission: {} },
        },
        {
          additionalProperties: false,
          required: ['undelegateSubmission'],
          properties: { undelegateSubmission: {} },
        },
        {
          additionalProperties: false,
          required: ['liquidityProvisionCancellation'],
          properties: { liquidityProvisionCancellation: {} },
        },
        {
          additionalProperties: false,
          required: ['liquidityProvisionAmendment'],
          properties: { liquidityProvisionAmendment: {} },
        },
        {
          additionalProperties: false,
          required: ['transfer'],
          properties: { transfer: {} },
        },
        {
          additionalProperties: false,
          required: ['cancelTransfer'],
          properties: { cancelTransfer: {} },
        },
        {
          additionalProperties: false,
          required: ['announceNode'],
          properties: { announceNode: {} },
        },
        {
          additionalProperties: false,
          required: ['batchMarketInstructions'],
          properties: { batchMarketInstructions: {} },
        },
        {
          additionalProperties: false,
          required: ['stopOrdersSubmission'],
          properties: { stopOrdersSubmission: {} },
        },
        {
          additionalProperties: false,
          required: ['stopOrdersCancellation'],
          properties: { stopOrdersCancellation: {} },
        },
        {
          additionalProperties: false,
          required: ['createReferralSet'],
          properties: { createReferralSet: {} },
        },
        {
          additionalProperties: false,
          required: ['updateReferralSet'],
          properties: { updateReferralSet: {} },
        },
        {
          additionalProperties: false,
          required: ['applyReferralCode'],
          properties: { applyReferralCode: {} },
        },
        {
          additionalProperties: false,
          required: ['updateMarginMode'],
          properties: { updateMarginMode: {} },
        },
        {
          additionalProperties: false,
          required: ['joinTeam'],
          properties: { joinTeam: {} },
        },
        {
          additionalProperties: false,
          required: ['batchProposalSubmission'],
          properties: { batchProposalSubmission: {} },
        },
        {
          additionalProperties: false,
          required: ['updatePartyProfile'],
          properties: { updatePartyProfile: {} },
        },
        {
          additionalProperties: false,
          required: ['submitAmm'],
          properties: { submitAmm: {} },
        },
        {
          additionalProperties: false,
          required: ['amendAmm'],
          properties: { amendAmm: {} },
        },
        {
          additionalProperties: false,
          required: ['cancelAmm'],
          properties: { cancelAmm: {} },
        },
        {
          additionalProperties: false,
          required: ['nodeVote'],
          properties: { nodeVote: {} },
        },
        {
          additionalProperties: false,
          required: ['nodeSignature'],
          properties: { nodeSignature: {} },
        },
        {
          additionalProperties: false,
          required: ['chainEvent'],
          properties: { chainEvent: {} },
        },
        {
          additionalProperties: false,
          required: ['keyRotateSubmission'],
          properties: { keyRotateSubmission: {} },
        },
        {
          additionalProperties: false,
          required: ['stateVariableProposal'],
          properties: { stateVariableProposal: {} },
        },
        {
          additionalProperties: false,
          required: ['validatorHeartbeat'],
          properties: { validatorHeartbeat: {} },
        },
        {
          additionalProperties: false,
          required: ['ethereumKeyRotateSubmission'],
          properties: { ethereumKeyRotateSubmission: {} },
        },
        {
          additionalProperties: false,
          required: ['protocolUpgradeProposal'],
          properties: { protocolUpgradeProposal: {} },
        },
        {
          additionalProperties: false,
          required: ['issueSignatures'],
          properties: { issueSignatures: {} },
        },
        {
          additionalProperties: false,
          required: ['oracleDataSubmission'],
          properties: { oracleDataSubmission: {} },
        },
        {
          additionalProperties: false,
          required: ['delayedTransactionsWrapper'],
          properties: { delayedTransactionsWrapper: {} },
        },
      ],
    },
  },
};
const pattern0 = new RegExp('^[0-9a-z]{64}$', 'u');
function validate14(
  data,
  { instancePath = '', parentData, parentDataProperty, rootData = data } = {}
) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == 'object' && !Array.isArray(data)) {
    if (data.publicKey === undefined) {
      const err0 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'publicKey' },
        message: "must have required property '" + 'publicKey' + "'",
      };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.transaction === undefined) {
      const err1 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'transaction' },
        message: "must have required property '" + 'transaction' + "'",
      };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.sendingMode === undefined) {
      const err2 = {
        instancePath,
        schemaPath: '#/required',
        keyword: 'required',
        params: { missingProperty: 'sendingMode' },
        message: "must have required property '" + 'sendingMode' + "'",
      };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    for (const key0 in data) {
      if (
        !(
          key0 === 'publicKey' ||
          key0 === 'sendingMode' ||
          key0 === 'transaction'
        )
      ) {
        const err3 = {
          instancePath,
          schemaPath: '#/additionalProperties',
          keyword: 'additionalProperties',
          params: { additionalProperty: key0 },
          message: 'must NOT have additional properties',
        };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.publicKey !== undefined) {
      let data0 = data.publicKey;
      if (typeof data0 === 'string') {
        if (!pattern0.test(data0)) {
          const err4 = {
            instancePath: instancePath + '/publicKey',
            schemaPath: '#/properties/publicKey/pattern',
            keyword: 'pattern',
            params: { pattern: '^[0-9a-z]{64}$' },
            message: 'must match pattern "' + '^[0-9a-z]{64}$' + '"',
          };
          if (vErrors === null) {
            vErrors = [err4];
          } else {
            vErrors.push(err4);
          }
          errors++;
        }
      } else {
        const err5 = {
          instancePath: instancePath + '/publicKey',
          schemaPath: '#/properties/publicKey/type',
          keyword: 'type',
          params: { type: 'string' },
          message: 'must be string',
        };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
      if (errors > 0) {
        const emErrs0 = [];
        for (const err6 of vErrors) {
          if (
            err6.keyword !== 'errorMessage' &&
            !err6.emUsed &&
            (err6.instancePath === instancePath + '/publicKey' ||
              (err6.instancePath.indexOf(instancePath + '/publicKey') === 0 &&
                err6.instancePath[instancePath + '/publicKey'.length] ===
                  '/')) &&
            err6.schemaPath.indexOf('#/properties/publicKey') === 0 &&
            err6.schemaPath['#/properties/publicKey'.length] === '/'
          ) {
            emErrs0.push(err6);
            err6.emUsed = true;
          }
        }
        if (emErrs0.length) {
          const err7 = {
            instancePath: instancePath + '/publicKey',
            schemaPath: '#/properties/publicKey/errorMessage',
            keyword: 'errorMessage',
            params: { errors: emErrs0 },
            message: '`publicKey` must be a 64 character hex encoded publicKey',
          };
          if (vErrors === null) {
            vErrors = [err7];
          } else {
            vErrors.push(err7);
          }
          errors++;
        }
        const emErrs1 = [];
        for (const err8 of vErrors) {
          if (!err8.emUsed) {
            emErrs1.push(err8);
          }
        }
        vErrors = emErrs1;
        errors = emErrs1.length;
      }
    }
    if (data.sendingMode !== undefined) {
      let data1 = data.sendingMode;
      if (
        !(
          data1 === 'TYPE_ASYNC' ||
          data1 === 1 ||
          data1 === 'TYPE_SYNC' ||
          data1 === 2 ||
          data1 === 'TYPE_COMMIT' ||
          data1 === 3
        )
      ) {
        const err9 = {
          instancePath: instancePath + '/sendingMode',
          schemaPath: '#/properties/sendingMode/enum',
          keyword: 'enum',
          params: { allowedValues: schema16.properties.sendingMode.enum },
          message: 'must be equal to one of the allowed values',
        };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
      if (errors > 0) {
        const emErrs2 = [];
        for (const err10 of vErrors) {
          if (
            err10.keyword !== 'errorMessage' &&
            !err10.emUsed &&
            (err10.instancePath === instancePath + '/sendingMode' ||
              (err10.instancePath.indexOf(instancePath + '/sendingMode') ===
                0 &&
                err10.instancePath[instancePath + '/sendingMode'.length] ===
                  '/')) &&
            err10.schemaPath.indexOf('#/properties/sendingMode') === 0 &&
            err10.schemaPath['#/properties/sendingMode'.length] === '/'
          ) {
            emErrs2.push(err10);
            err10.emUsed = true;
          }
        }
        if (emErrs2.length) {
          const err11 = {
            instancePath: instancePath + '/sendingMode',
            schemaPath: '#/properties/sendingMode/errorMessage',
            keyword: 'errorMessage',
            params: { errors: emErrs2 },
            message:
              'Only `TYPE_ASYNC` (1), `TYPE_SYNC` (2) and `TYPE_COMMIT` (3) are valid sending modes. The sendingMode must be the string name or numerical enum',
          };
          if (vErrors === null) {
            vErrors = [err11];
          } else {
            vErrors.push(err11);
          }
          errors++;
        }
        const emErrs3 = [];
        for (const err12 of vErrors) {
          if (!err12.emUsed) {
            emErrs3.push(err12);
          }
        }
        vErrors = emErrs3;
        errors = emErrs3.length;
      }
    }
    if (data.transaction !== undefined) {
      let data2 = data.transaction;
      if (!(data2 && typeof data2 == 'object' && !Array.isArray(data2))) {
        const err13 = {
          instancePath: instancePath + '/transaction',
          schemaPath: '#/properties/transaction/type',
          keyword: 'type',
          params: { type: 'object' },
          message: 'must be object',
        };
        if (vErrors === null) {
          vErrors = [err13];
        } else {
          vErrors.push(err13);
        }
        errors++;
      }
      const _errs7 = errors;
      let valid1 = false;
      let passing0 = null;
      const _errs8 = errors;
      if (data2 && typeof data2 == 'object' && !Array.isArray(data2)) {
        if (data2.orderSubmission === undefined) {
          const err14 = {
            instancePath: instancePath + '/transaction',
            schemaPath: '#/properties/transaction/oneOf/0/required',
            keyword: 'required',
            params: { missingProperty: 'orderSubmission' },
            message: "must have required property '" + 'orderSubmission' + "'",
          };
          if (vErrors === null) {
            vErrors = [err14];
          } else {
            vErrors.push(err14);
          }
          errors++;
        }
        for (const key1 in data2) {
          if (!(key1 === 'orderSubmission')) {
            const err15 = {
              instancePath: instancePath + '/transaction',
              schemaPath:
                '#/properties/transaction/oneOf/0/additionalProperties',
              keyword: 'additionalProperties',
              params: { additionalProperty: key1 },
              message: 'must NOT have additional properties',
            };
            if (vErrors === null) {
              vErrors = [err15];
            } else {
              vErrors.push(err15);
            }
            errors++;
          }
        }
      }
      var _valid0 = _errs8 === errors;
      if (_valid0) {
        valid1 = true;
        passing0 = 0;
      }
      const _errs10 = errors;
      if (data2 && typeof data2 == 'object' && !Array.isArray(data2)) {
        if (data2.orderCancellation === undefined) {
          const err16 = {
            instancePath: instancePath + '/transaction',
            schemaPath: '#/properties/transaction/oneOf/1/required',
            keyword: 'required',
            params: { missingProperty: 'orderCancellation' },
            message:
              "must have required property '" + 'orderCancellation' + "'",
          };
          if (vErrors === null) {
            vErrors = [err16];
          } else {
            vErrors.push(err16);
          }
          errors++;
        }
        for (const key2 in data2) {
          if (!(key2 === 'orderCancellation')) {
            const err17 = {
              instancePath: instancePath + '/transaction',
              schemaPath:
                '#/properties/transaction/oneOf/1/additionalProperties',
              keyword: 'additionalProperties',
              params: { additionalProperty: key2 },
              message: 'must NOT have additional properties',
            };
            if (vErrors === null) {
              vErrors = [err17];
            } else {
              vErrors.push(err17);
            }
            errors++;
          }
        }
      }
      var _valid0 = _errs10 === errors;
      if (_valid0 && valid1) {
        valid1 = false;
        passing0 = [passing0, 1];
      } else {
        if (_valid0) {
          valid1 = true;
          passing0 = 1;
        }
        const _errs12 = errors;
        if (data2 && typeof data2 == 'object' && !Array.isArray(data2)) {
          if (data2.orderAmendment === undefined) {
            const err18 = {
              instancePath: instancePath + '/transaction',
              schemaPath: '#/properties/transaction/oneOf/2/required',
              keyword: 'required',
              params: { missingProperty: 'orderAmendment' },
              message: "must have required property '" + 'orderAmendment' + "'",
            };
            if (vErrors === null) {
              vErrors = [err18];
            } else {
              vErrors.push(err18);
            }
            errors++;
          }
          for (const key3 in data2) {
            if (!(key3 === 'orderAmendment')) {
              const err19 = {
                instancePath: instancePath + '/transaction',
                schemaPath:
                  '#/properties/transaction/oneOf/2/additionalProperties',
                keyword: 'additionalProperties',
                params: { additionalProperty: key3 },
                message: 'must NOT have additional properties',
              };
              if (vErrors === null) {
                vErrors = [err19];
              } else {
                vErrors.push(err19);
              }
              errors++;
            }
          }
        }
        var _valid0 = _errs12 === errors;
        if (_valid0 && valid1) {
          valid1 = false;
          passing0 = [passing0, 2];
        } else {
          if (_valid0) {
            valid1 = true;
            passing0 = 2;
          }
          const _errs14 = errors;
          if (data2 && typeof data2 == 'object' && !Array.isArray(data2)) {
            if (data2.withdrawSubmission === undefined) {
              const err20 = {
                instancePath: instancePath + '/transaction',
                schemaPath: '#/properties/transaction/oneOf/3/required',
                keyword: 'required',
                params: { missingProperty: 'withdrawSubmission' },
                message:
                  "must have required property '" + 'withdrawSubmission' + "'",
              };
              if (vErrors === null) {
                vErrors = [err20];
              } else {
                vErrors.push(err20);
              }
              errors++;
            }
            for (const key4 in data2) {
              if (!(key4 === 'withdrawSubmission')) {
                const err21 = {
                  instancePath: instancePath + '/transaction',
                  schemaPath:
                    '#/properties/transaction/oneOf/3/additionalProperties',
                  keyword: 'additionalProperties',
                  params: { additionalProperty: key4 },
                  message: 'must NOT have additional properties',
                };
                if (vErrors === null) {
                  vErrors = [err21];
                } else {
                  vErrors.push(err21);
                }
                errors++;
              }
            }
          }
          var _valid0 = _errs14 === errors;
          if (_valid0 && valid1) {
            valid1 = false;
            passing0 = [passing0, 3];
          } else {
            if (_valid0) {
              valid1 = true;
              passing0 = 3;
            }
            const _errs16 = errors;
            if (data2 && typeof data2 == 'object' && !Array.isArray(data2)) {
              if (data2.proposalSubmission === undefined) {
                const err22 = {
                  instancePath: instancePath + '/transaction',
                  schemaPath: '#/properties/transaction/oneOf/4/required',
                  keyword: 'required',
                  params: { missingProperty: 'proposalSubmission' },
                  message:
                    "must have required property '" +
                    'proposalSubmission' +
                    "'",
                };
                if (vErrors === null) {
                  vErrors = [err22];
                } else {
                  vErrors.push(err22);
                }
                errors++;
              }
              for (const key5 in data2) {
                if (!(key5 === 'proposalSubmission')) {
                  const err23 = {
                    instancePath: instancePath + '/transaction',
                    schemaPath:
                      '#/properties/transaction/oneOf/4/additionalProperties',
                    keyword: 'additionalProperties',
                    params: { additionalProperty: key5 },
                    message: 'must NOT have additional properties',
                  };
                  if (vErrors === null) {
                    vErrors = [err23];
                  } else {
                    vErrors.push(err23);
                  }
                  errors++;
                }
              }
            }
            var _valid0 = _errs16 === errors;
            if (_valid0 && valid1) {
              valid1 = false;
              passing0 = [passing0, 4];
            } else {
              if (_valid0) {
                valid1 = true;
                passing0 = 4;
              }
              const _errs18 = errors;
              if (data2 && typeof data2 == 'object' && !Array.isArray(data2)) {
                if (data2.voteSubmission === undefined) {
                  const err24 = {
                    instancePath: instancePath + '/transaction',
                    schemaPath: '#/properties/transaction/oneOf/5/required',
                    keyword: 'required',
                    params: { missingProperty: 'voteSubmission' },
                    message:
                      "must have required property '" + 'voteSubmission' + "'",
                  };
                  if (vErrors === null) {
                    vErrors = [err24];
                  } else {
                    vErrors.push(err24);
                  }
                  errors++;
                }
                for (const key6 in data2) {
                  if (!(key6 === 'voteSubmission')) {
                    const err25 = {
                      instancePath: instancePath + '/transaction',
                      schemaPath:
                        '#/properties/transaction/oneOf/5/additionalProperties',
                      keyword: 'additionalProperties',
                      params: { additionalProperty: key6 },
                      message: 'must NOT have additional properties',
                    };
                    if (vErrors === null) {
                      vErrors = [err25];
                    } else {
                      vErrors.push(err25);
                    }
                    errors++;
                  }
                }
              }
              var _valid0 = _errs18 === errors;
              if (_valid0 && valid1) {
                valid1 = false;
                passing0 = [passing0, 5];
              } else {
                if (_valid0) {
                  valid1 = true;
                  passing0 = 5;
                }
                const _errs20 = errors;
                if (
                  data2 &&
                  typeof data2 == 'object' &&
                  !Array.isArray(data2)
                ) {
                  if (data2.liquidityProvisionSubmission === undefined) {
                    const err26 = {
                      instancePath: instancePath + '/transaction',
                      schemaPath: '#/properties/transaction/oneOf/6/required',
                      keyword: 'required',
                      params: {
                        missingProperty: 'liquidityProvisionSubmission',
                      },
                      message:
                        "must have required property '" +
                        'liquidityProvisionSubmission' +
                        "'",
                    };
                    if (vErrors === null) {
                      vErrors = [err26];
                    } else {
                      vErrors.push(err26);
                    }
                    errors++;
                  }
                  for (const key7 in data2) {
                    if (!(key7 === 'liquidityProvisionSubmission')) {
                      const err27 = {
                        instancePath: instancePath + '/transaction',
                        schemaPath:
                          '#/properties/transaction/oneOf/6/additionalProperties',
                        keyword: 'additionalProperties',
                        params: { additionalProperty: key7 },
                        message: 'must NOT have additional properties',
                      };
                      if (vErrors === null) {
                        vErrors = [err27];
                      } else {
                        vErrors.push(err27);
                      }
                      errors++;
                    }
                  }
                }
                var _valid0 = _errs20 === errors;
                if (_valid0 && valid1) {
                  valid1 = false;
                  passing0 = [passing0, 6];
                } else {
                  if (_valid0) {
                    valid1 = true;
                    passing0 = 6;
                  }
                  const _errs22 = errors;
                  if (
                    data2 &&
                    typeof data2 == 'object' &&
                    !Array.isArray(data2)
                  ) {
                    if (data2.delegateSubmission === undefined) {
                      const err28 = {
                        instancePath: instancePath + '/transaction',
                        schemaPath: '#/properties/transaction/oneOf/7/required',
                        keyword: 'required',
                        params: { missingProperty: 'delegateSubmission' },
                        message:
                          "must have required property '" +
                          'delegateSubmission' +
                          "'",
                      };
                      if (vErrors === null) {
                        vErrors = [err28];
                      } else {
                        vErrors.push(err28);
                      }
                      errors++;
                    }
                    for (const key8 in data2) {
                      if (!(key8 === 'delegateSubmission')) {
                        const err29 = {
                          instancePath: instancePath + '/transaction',
                          schemaPath:
                            '#/properties/transaction/oneOf/7/additionalProperties',
                          keyword: 'additionalProperties',
                          params: { additionalProperty: key8 },
                          message: 'must NOT have additional properties',
                        };
                        if (vErrors === null) {
                          vErrors = [err29];
                        } else {
                          vErrors.push(err29);
                        }
                        errors++;
                      }
                    }
                  }
                  var _valid0 = _errs22 === errors;
                  if (_valid0 && valid1) {
                    valid1 = false;
                    passing0 = [passing0, 7];
                  } else {
                    if (_valid0) {
                      valid1 = true;
                      passing0 = 7;
                    }
                    const _errs24 = errors;
                    if (
                      data2 &&
                      typeof data2 == 'object' &&
                      !Array.isArray(data2)
                    ) {
                      if (data2.undelegateSubmission === undefined) {
                        const err30 = {
                          instancePath: instancePath + '/transaction',
                          schemaPath:
                            '#/properties/transaction/oneOf/8/required',
                          keyword: 'required',
                          params: { missingProperty: 'undelegateSubmission' },
                          message:
                            "must have required property '" +
                            'undelegateSubmission' +
                            "'",
                        };
                        if (vErrors === null) {
                          vErrors = [err30];
                        } else {
                          vErrors.push(err30);
                        }
                        errors++;
                      }
                      for (const key9 in data2) {
                        if (!(key9 === 'undelegateSubmission')) {
                          const err31 = {
                            instancePath: instancePath + '/transaction',
                            schemaPath:
                              '#/properties/transaction/oneOf/8/additionalProperties',
                            keyword: 'additionalProperties',
                            params: { additionalProperty: key9 },
                            message: 'must NOT have additional properties',
                          };
                          if (vErrors === null) {
                            vErrors = [err31];
                          } else {
                            vErrors.push(err31);
                          }
                          errors++;
                        }
                      }
                    }
                    var _valid0 = _errs24 === errors;
                    if (_valid0 && valid1) {
                      valid1 = false;
                      passing0 = [passing0, 8];
                    } else {
                      if (_valid0) {
                        valid1 = true;
                        passing0 = 8;
                      }
                      const _errs26 = errors;
                      if (
                        data2 &&
                        typeof data2 == 'object' &&
                        !Array.isArray(data2)
                      ) {
                        if (
                          data2.liquidityProvisionCancellation === undefined
                        ) {
                          const err32 = {
                            instancePath: instancePath + '/transaction',
                            schemaPath:
                              '#/properties/transaction/oneOf/9/required',
                            keyword: 'required',
                            params: {
                              missingProperty: 'liquidityProvisionCancellation',
                            },
                            message:
                              "must have required property '" +
                              'liquidityProvisionCancellation' +
                              "'",
                          };
                          if (vErrors === null) {
                            vErrors = [err32];
                          } else {
                            vErrors.push(err32);
                          }
                          errors++;
                        }
                        for (const key10 in data2) {
                          if (!(key10 === 'liquidityProvisionCancellation')) {
                            const err33 = {
                              instancePath: instancePath + '/transaction',
                              schemaPath:
                                '#/properties/transaction/oneOf/9/additionalProperties',
                              keyword: 'additionalProperties',
                              params: { additionalProperty: key10 },
                              message: 'must NOT have additional properties',
                            };
                            if (vErrors === null) {
                              vErrors = [err33];
                            } else {
                              vErrors.push(err33);
                            }
                            errors++;
                          }
                        }
                      }
                      var _valid0 = _errs26 === errors;
                      if (_valid0 && valid1) {
                        valid1 = false;
                        passing0 = [passing0, 9];
                      } else {
                        if (_valid0) {
                          valid1 = true;
                          passing0 = 9;
                        }
                        const _errs28 = errors;
                        if (
                          data2 &&
                          typeof data2 == 'object' &&
                          !Array.isArray(data2)
                        ) {
                          if (data2.liquidityProvisionAmendment === undefined) {
                            const err34 = {
                              instancePath: instancePath + '/transaction',
                              schemaPath:
                                '#/properties/transaction/oneOf/10/required',
                              keyword: 'required',
                              params: {
                                missingProperty: 'liquidityProvisionAmendment',
                              },
                              message:
                                "must have required property '" +
                                'liquidityProvisionAmendment' +
                                "'",
                            };
                            if (vErrors === null) {
                              vErrors = [err34];
                            } else {
                              vErrors.push(err34);
                            }
                            errors++;
                          }
                          for (const key11 in data2) {
                            if (!(key11 === 'liquidityProvisionAmendment')) {
                              const err35 = {
                                instancePath: instancePath + '/transaction',
                                schemaPath:
                                  '#/properties/transaction/oneOf/10/additionalProperties',
                                keyword: 'additionalProperties',
                                params: { additionalProperty: key11 },
                                message: 'must NOT have additional properties',
                              };
                              if (vErrors === null) {
                                vErrors = [err35];
                              } else {
                                vErrors.push(err35);
                              }
                              errors++;
                            }
                          }
                        }
                        var _valid0 = _errs28 === errors;
                        if (_valid0 && valid1) {
                          valid1 = false;
                          passing0 = [passing0, 10];
                        } else {
                          if (_valid0) {
                            valid1 = true;
                            passing0 = 10;
                          }
                          const _errs30 = errors;
                          if (
                            data2 &&
                            typeof data2 == 'object' &&
                            !Array.isArray(data2)
                          ) {
                            if (data2.transfer === undefined) {
                              const err36 = {
                                instancePath: instancePath + '/transaction',
                                schemaPath:
                                  '#/properties/transaction/oneOf/11/required',
                                keyword: 'required',
                                params: { missingProperty: 'transfer' },
                                message:
                                  "must have required property '" +
                                  'transfer' +
                                  "'",
                              };
                              if (vErrors === null) {
                                vErrors = [err36];
                              } else {
                                vErrors.push(err36);
                              }
                              errors++;
                            }
                            for (const key12 in data2) {
                              if (!(key12 === 'transfer')) {
                                const err37 = {
                                  instancePath: instancePath + '/transaction',
                                  schemaPath:
                                    '#/properties/transaction/oneOf/11/additionalProperties',
                                  keyword: 'additionalProperties',
                                  params: { additionalProperty: key12 },
                                  message:
                                    'must NOT have additional properties',
                                };
                                if (vErrors === null) {
                                  vErrors = [err37];
                                } else {
                                  vErrors.push(err37);
                                }
                                errors++;
                              }
                            }
                          }
                          var _valid0 = _errs30 === errors;
                          if (_valid0 && valid1) {
                            valid1 = false;
                            passing0 = [passing0, 11];
                          } else {
                            if (_valid0) {
                              valid1 = true;
                              passing0 = 11;
                            }
                            const _errs32 = errors;
                            if (
                              data2 &&
                              typeof data2 == 'object' &&
                              !Array.isArray(data2)
                            ) {
                              if (data2.cancelTransfer === undefined) {
                                const err38 = {
                                  instancePath: instancePath + '/transaction',
                                  schemaPath:
                                    '#/properties/transaction/oneOf/12/required',
                                  keyword: 'required',
                                  params: { missingProperty: 'cancelTransfer' },
                                  message:
                                    "must have required property '" +
                                    'cancelTransfer' +
                                    "'",
                                };
                                if (vErrors === null) {
                                  vErrors = [err38];
                                } else {
                                  vErrors.push(err38);
                                }
                                errors++;
                              }
                              for (const key13 in data2) {
                                if (!(key13 === 'cancelTransfer')) {
                                  const err39 = {
                                    instancePath: instancePath + '/transaction',
                                    schemaPath:
                                      '#/properties/transaction/oneOf/12/additionalProperties',
                                    keyword: 'additionalProperties',
                                    params: { additionalProperty: key13 },
                                    message:
                                      'must NOT have additional properties',
                                  };
                                  if (vErrors === null) {
                                    vErrors = [err39];
                                  } else {
                                    vErrors.push(err39);
                                  }
                                  errors++;
                                }
                              }
                            }
                            var _valid0 = _errs32 === errors;
                            if (_valid0 && valid1) {
                              valid1 = false;
                              passing0 = [passing0, 12];
                            } else {
                              if (_valid0) {
                                valid1 = true;
                                passing0 = 12;
                              }
                              const _errs34 = errors;
                              if (
                                data2 &&
                                typeof data2 == 'object' &&
                                !Array.isArray(data2)
                              ) {
                                if (data2.announceNode === undefined) {
                                  const err40 = {
                                    instancePath: instancePath + '/transaction',
                                    schemaPath:
                                      '#/properties/transaction/oneOf/13/required',
                                    keyword: 'required',
                                    params: { missingProperty: 'announceNode' },
                                    message:
                                      "must have required property '" +
                                      'announceNode' +
                                      "'",
                                  };
                                  if (vErrors === null) {
                                    vErrors = [err40];
                                  } else {
                                    vErrors.push(err40);
                                  }
                                  errors++;
                                }
                                for (const key14 in data2) {
                                  if (!(key14 === 'announceNode')) {
                                    const err41 = {
                                      instancePath:
                                        instancePath + '/transaction',
                                      schemaPath:
                                        '#/properties/transaction/oneOf/13/additionalProperties',
                                      keyword: 'additionalProperties',
                                      params: { additionalProperty: key14 },
                                      message:
                                        'must NOT have additional properties',
                                    };
                                    if (vErrors === null) {
                                      vErrors = [err41];
                                    } else {
                                      vErrors.push(err41);
                                    }
                                    errors++;
                                  }
                                }
                              }
                              var _valid0 = _errs34 === errors;
                              if (_valid0 && valid1) {
                                valid1 = false;
                                passing0 = [passing0, 13];
                              } else {
                                if (_valid0) {
                                  valid1 = true;
                                  passing0 = 13;
                                }
                                const _errs36 = errors;
                                if (
                                  data2 &&
                                  typeof data2 == 'object' &&
                                  !Array.isArray(data2)
                                ) {
                                  if (
                                    data2.batchMarketInstructions === undefined
                                  ) {
                                    const err42 = {
                                      instancePath:
                                        instancePath + '/transaction',
                                      schemaPath:
                                        '#/properties/transaction/oneOf/14/required',
                                      keyword: 'required',
                                      params: {
                                        missingProperty:
                                          'batchMarketInstructions',
                                      },
                                      message:
                                        "must have required property '" +
                                        'batchMarketInstructions' +
                                        "'",
                                    };
                                    if (vErrors === null) {
                                      vErrors = [err42];
                                    } else {
                                      vErrors.push(err42);
                                    }
                                    errors++;
                                  }
                                  for (const key15 in data2) {
                                    if (
                                      !(key15 === 'batchMarketInstructions')
                                    ) {
                                      const err43 = {
                                        instancePath:
                                          instancePath + '/transaction',
                                        schemaPath:
                                          '#/properties/transaction/oneOf/14/additionalProperties',
                                        keyword: 'additionalProperties',
                                        params: { additionalProperty: key15 },
                                        message:
                                          'must NOT have additional properties',
                                      };
                                      if (vErrors === null) {
                                        vErrors = [err43];
                                      } else {
                                        vErrors.push(err43);
                                      }
                                      errors++;
                                    }
                                  }
                                }
                                var _valid0 = _errs36 === errors;
                                if (_valid0 && valid1) {
                                  valid1 = false;
                                  passing0 = [passing0, 14];
                                } else {
                                  if (_valid0) {
                                    valid1 = true;
                                    passing0 = 14;
                                  }
                                  const _errs38 = errors;
                                  if (
                                    data2 &&
                                    typeof data2 == 'object' &&
                                    !Array.isArray(data2)
                                  ) {
                                    if (
                                      data2.stopOrdersSubmission === undefined
                                    ) {
                                      const err44 = {
                                        instancePath:
                                          instancePath + '/transaction',
                                        schemaPath:
                                          '#/properties/transaction/oneOf/15/required',
                                        keyword: 'required',
                                        params: {
                                          missingProperty:
                                            'stopOrdersSubmission',
                                        },
                                        message:
                                          "must have required property '" +
                                          'stopOrdersSubmission' +
                                          "'",
                                      };
                                      if (vErrors === null) {
                                        vErrors = [err44];
                                      } else {
                                        vErrors.push(err44);
                                      }
                                      errors++;
                                    }
                                    for (const key16 in data2) {
                                      if (!(key16 === 'stopOrdersSubmission')) {
                                        const err45 = {
                                          instancePath:
                                            instancePath + '/transaction',
                                          schemaPath:
                                            '#/properties/transaction/oneOf/15/additionalProperties',
                                          keyword: 'additionalProperties',
                                          params: { additionalProperty: key16 },
                                          message:
                                            'must NOT have additional properties',
                                        };
                                        if (vErrors === null) {
                                          vErrors = [err45];
                                        } else {
                                          vErrors.push(err45);
                                        }
                                        errors++;
                                      }
                                    }
                                  }
                                  var _valid0 = _errs38 === errors;
                                  if (_valid0 && valid1) {
                                    valid1 = false;
                                    passing0 = [passing0, 15];
                                  } else {
                                    if (_valid0) {
                                      valid1 = true;
                                      passing0 = 15;
                                    }
                                    const _errs40 = errors;
                                    if (
                                      data2 &&
                                      typeof data2 == 'object' &&
                                      !Array.isArray(data2)
                                    ) {
                                      if (
                                        data2.stopOrdersCancellation ===
                                        undefined
                                      ) {
                                        const err46 = {
                                          instancePath:
                                            instancePath + '/transaction',
                                          schemaPath:
                                            '#/properties/transaction/oneOf/16/required',
                                          keyword: 'required',
                                          params: {
                                            missingProperty:
                                              'stopOrdersCancellation',
                                          },
                                          message:
                                            "must have required property '" +
                                            'stopOrdersCancellation' +
                                            "'",
                                        };
                                        if (vErrors === null) {
                                          vErrors = [err46];
                                        } else {
                                          vErrors.push(err46);
                                        }
                                        errors++;
                                      }
                                      for (const key17 in data2) {
                                        if (
                                          !(key17 === 'stopOrdersCancellation')
                                        ) {
                                          const err47 = {
                                            instancePath:
                                              instancePath + '/transaction',
                                            schemaPath:
                                              '#/properties/transaction/oneOf/16/additionalProperties',
                                            keyword: 'additionalProperties',
                                            params: {
                                              additionalProperty: key17,
                                            },
                                            message:
                                              'must NOT have additional properties',
                                          };
                                          if (vErrors === null) {
                                            vErrors = [err47];
                                          } else {
                                            vErrors.push(err47);
                                          }
                                          errors++;
                                        }
                                      }
                                    }
                                    var _valid0 = _errs40 === errors;
                                    if (_valid0 && valid1) {
                                      valid1 = false;
                                      passing0 = [passing0, 16];
                                    } else {
                                      if (_valid0) {
                                        valid1 = true;
                                        passing0 = 16;
                                      }
                                      const _errs42 = errors;
                                      if (
                                        data2 &&
                                        typeof data2 == 'object' &&
                                        !Array.isArray(data2)
                                      ) {
                                        if (
                                          data2.createReferralSet === undefined
                                        ) {
                                          const err48 = {
                                            instancePath:
                                              instancePath + '/transaction',
                                            schemaPath:
                                              '#/properties/transaction/oneOf/17/required',
                                            keyword: 'required',
                                            params: {
                                              missingProperty:
                                                'createReferralSet',
                                            },
                                            message:
                                              "must have required property '" +
                                              'createReferralSet' +
                                              "'",
                                          };
                                          if (vErrors === null) {
                                            vErrors = [err48];
                                          } else {
                                            vErrors.push(err48);
                                          }
                                          errors++;
                                        }
                                        for (const key18 in data2) {
                                          if (
                                            !(key18 === 'createReferralSet')
                                          ) {
                                            const err49 = {
                                              instancePath:
                                                instancePath + '/transaction',
                                              schemaPath:
                                                '#/properties/transaction/oneOf/17/additionalProperties',
                                              keyword: 'additionalProperties',
                                              params: {
                                                additionalProperty: key18,
                                              },
                                              message:
                                                'must NOT have additional properties',
                                            };
                                            if (vErrors === null) {
                                              vErrors = [err49];
                                            } else {
                                              vErrors.push(err49);
                                            }
                                            errors++;
                                          }
                                        }
                                      }
                                      var _valid0 = _errs42 === errors;
                                      if (_valid0 && valid1) {
                                        valid1 = false;
                                        passing0 = [passing0, 17];
                                      } else {
                                        if (_valid0) {
                                          valid1 = true;
                                          passing0 = 17;
                                        }
                                        const _errs44 = errors;
                                        if (
                                          data2 &&
                                          typeof data2 == 'object' &&
                                          !Array.isArray(data2)
                                        ) {
                                          if (
                                            data2.updateReferralSet ===
                                            undefined
                                          ) {
                                            const err50 = {
                                              instancePath:
                                                instancePath + '/transaction',
                                              schemaPath:
                                                '#/properties/transaction/oneOf/18/required',
                                              keyword: 'required',
                                              params: {
                                                missingProperty:
                                                  'updateReferralSet',
                                              },
                                              message:
                                                "must have required property '" +
                                                'updateReferralSet' +
                                                "'",
                                            };
                                            if (vErrors === null) {
                                              vErrors = [err50];
                                            } else {
                                              vErrors.push(err50);
                                            }
                                            errors++;
                                          }
                                          for (const key19 in data2) {
                                            if (
                                              !(key19 === 'updateReferralSet')
                                            ) {
                                              const err51 = {
                                                instancePath:
                                                  instancePath + '/transaction',
                                                schemaPath:
                                                  '#/properties/transaction/oneOf/18/additionalProperties',
                                                keyword: 'additionalProperties',
                                                params: {
                                                  additionalProperty: key19,
                                                },
                                                message:
                                                  'must NOT have additional properties',
                                              };
                                              if (vErrors === null) {
                                                vErrors = [err51];
                                              } else {
                                                vErrors.push(err51);
                                              }
                                              errors++;
                                            }
                                          }
                                        }
                                        var _valid0 = _errs44 === errors;
                                        if (_valid0 && valid1) {
                                          valid1 = false;
                                          passing0 = [passing0, 18];
                                        } else {
                                          if (_valid0) {
                                            valid1 = true;
                                            passing0 = 18;
                                          }
                                          const _errs46 = errors;
                                          if (
                                            data2 &&
                                            typeof data2 == 'object' &&
                                            !Array.isArray(data2)
                                          ) {
                                            if (
                                              data2.applyReferralCode ===
                                              undefined
                                            ) {
                                              const err52 = {
                                                instancePath:
                                                  instancePath + '/transaction',
                                                schemaPath:
                                                  '#/properties/transaction/oneOf/19/required',
                                                keyword: 'required',
                                                params: {
                                                  missingProperty:
                                                    'applyReferralCode',
                                                },
                                                message:
                                                  "must have required property '" +
                                                  'applyReferralCode' +
                                                  "'",
                                              };
                                              if (vErrors === null) {
                                                vErrors = [err52];
                                              } else {
                                                vErrors.push(err52);
                                              }
                                              errors++;
                                            }
                                            for (const key20 in data2) {
                                              if (
                                                !(key20 === 'applyReferralCode')
                                              ) {
                                                const err53 = {
                                                  instancePath:
                                                    instancePath +
                                                    '/transaction',
                                                  schemaPath:
                                                    '#/properties/transaction/oneOf/19/additionalProperties',
                                                  keyword:
                                                    'additionalProperties',
                                                  params: {
                                                    additionalProperty: key20,
                                                  },
                                                  message:
                                                    'must NOT have additional properties',
                                                };
                                                if (vErrors === null) {
                                                  vErrors = [err53];
                                                } else {
                                                  vErrors.push(err53);
                                                }
                                                errors++;
                                              }
                                            }
                                          }
                                          var _valid0 = _errs46 === errors;
                                          if (_valid0 && valid1) {
                                            valid1 = false;
                                            passing0 = [passing0, 19];
                                          } else {
                                            if (_valid0) {
                                              valid1 = true;
                                              passing0 = 19;
                                            }
                                            const _errs48 = errors;
                                            if (
                                              data2 &&
                                              typeof data2 == 'object' &&
                                              !Array.isArray(data2)
                                            ) {
                                              if (
                                                data2.updateMarginMode ===
                                                undefined
                                              ) {
                                                const err54 = {
                                                  instancePath:
                                                    instancePath +
                                                    '/transaction',
                                                  schemaPath:
                                                    '#/properties/transaction/oneOf/20/required',
                                                  keyword: 'required',
                                                  params: {
                                                    missingProperty:
                                                      'updateMarginMode',
                                                  },
                                                  message:
                                                    "must have required property '" +
                                                    'updateMarginMode' +
                                                    "'",
                                                };
                                                if (vErrors === null) {
                                                  vErrors = [err54];
                                                } else {
                                                  vErrors.push(err54);
                                                }
                                                errors++;
                                              }
                                              for (const key21 in data2) {
                                                if (
                                                  !(
                                                    key21 === 'updateMarginMode'
                                                  )
                                                ) {
                                                  const err55 = {
                                                    instancePath:
                                                      instancePath +
                                                      '/transaction',
                                                    schemaPath:
                                                      '#/properties/transaction/oneOf/20/additionalProperties',
                                                    keyword:
                                                      'additionalProperties',
                                                    params: {
                                                      additionalProperty: key21,
                                                    },
                                                    message:
                                                      'must NOT have additional properties',
                                                  };
                                                  if (vErrors === null) {
                                                    vErrors = [err55];
                                                  } else {
                                                    vErrors.push(err55);
                                                  }
                                                  errors++;
                                                }
                                              }
                                            }
                                            var _valid0 = _errs48 === errors;
                                            if (_valid0 && valid1) {
                                              valid1 = false;
                                              passing0 = [passing0, 20];
                                            } else {
                                              if (_valid0) {
                                                valid1 = true;
                                                passing0 = 20;
                                              }
                                              const _errs50 = errors;
                                              if (
                                                data2 &&
                                                typeof data2 == 'object' &&
                                                !Array.isArray(data2)
                                              ) {
                                                if (
                                                  data2.joinTeam === undefined
                                                ) {
                                                  const err56 = {
                                                    instancePath:
                                                      instancePath +
                                                      '/transaction',
                                                    schemaPath:
                                                      '#/properties/transaction/oneOf/21/required',
                                                    keyword: 'required',
                                                    params: {
                                                      missingProperty:
                                                        'joinTeam',
                                                    },
                                                    message:
                                                      "must have required property '" +
                                                      'joinTeam' +
                                                      "'",
                                                  };
                                                  if (vErrors === null) {
                                                    vErrors = [err56];
                                                  } else {
                                                    vErrors.push(err56);
                                                  }
                                                  errors++;
                                                }
                                                for (const key22 in data2) {
                                                  if (!(key22 === 'joinTeam')) {
                                                    const err57 = {
                                                      instancePath:
                                                        instancePath +
                                                        '/transaction',
                                                      schemaPath:
                                                        '#/properties/transaction/oneOf/21/additionalProperties',
                                                      keyword:
                                                        'additionalProperties',
                                                      params: {
                                                        additionalProperty:
                                                          key22,
                                                      },
                                                      message:
                                                        'must NOT have additional properties',
                                                    };
                                                    if (vErrors === null) {
                                                      vErrors = [err57];
                                                    } else {
                                                      vErrors.push(err57);
                                                    }
                                                    errors++;
                                                  }
                                                }
                                              }
                                              var _valid0 = _errs50 === errors;
                                              if (_valid0 && valid1) {
                                                valid1 = false;
                                                passing0 = [passing0, 21];
                                              } else {
                                                if (_valid0) {
                                                  valid1 = true;
                                                  passing0 = 21;
                                                }
                                                const _errs52 = errors;
                                                if (
                                                  data2 &&
                                                  typeof data2 == 'object' &&
                                                  !Array.isArray(data2)
                                                ) {
                                                  if (
                                                    data2.batchProposalSubmission ===
                                                    undefined
                                                  ) {
                                                    const err58 = {
                                                      instancePath:
                                                        instancePath +
                                                        '/transaction',
                                                      schemaPath:
                                                        '#/properties/transaction/oneOf/22/required',
                                                      keyword: 'required',
                                                      params: {
                                                        missingProperty:
                                                          'batchProposalSubmission',
                                                      },
                                                      message:
                                                        "must have required property '" +
                                                        'batchProposalSubmission' +
                                                        "'",
                                                    };
                                                    if (vErrors === null) {
                                                      vErrors = [err58];
                                                    } else {
                                                      vErrors.push(err58);
                                                    }
                                                    errors++;
                                                  }
                                                  for (const key23 in data2) {
                                                    if (
                                                      !(
                                                        key23 ===
                                                        'batchProposalSubmission'
                                                      )
                                                    ) {
                                                      const err59 = {
                                                        instancePath:
                                                          instancePath +
                                                          '/transaction',
                                                        schemaPath:
                                                          '#/properties/transaction/oneOf/22/additionalProperties',
                                                        keyword:
                                                          'additionalProperties',
                                                        params: {
                                                          additionalProperty:
                                                            key23,
                                                        },
                                                        message:
                                                          'must NOT have additional properties',
                                                      };
                                                      if (vErrors === null) {
                                                        vErrors = [err59];
                                                      } else {
                                                        vErrors.push(err59);
                                                      }
                                                      errors++;
                                                    }
                                                  }
                                                }
                                                var _valid0 =
                                                  _errs52 === errors;
                                                if (_valid0 && valid1) {
                                                  valid1 = false;
                                                  passing0 = [passing0, 22];
                                                } else {
                                                  if (_valid0) {
                                                    valid1 = true;
                                                    passing0 = 22;
                                                  }
                                                  const _errs54 = errors;
                                                  if (
                                                    data2 &&
                                                    typeof data2 == 'object' &&
                                                    !Array.isArray(data2)
                                                  ) {
                                                    if (
                                                      data2.updatePartyProfile ===
                                                      undefined
                                                    ) {
                                                      const err60 = {
                                                        instancePath:
                                                          instancePath +
                                                          '/transaction',
                                                        schemaPath:
                                                          '#/properties/transaction/oneOf/23/required',
                                                        keyword: 'required',
                                                        params: {
                                                          missingProperty:
                                                            'updatePartyProfile',
                                                        },
                                                        message:
                                                          "must have required property '" +
                                                          'updatePartyProfile' +
                                                          "'",
                                                      };
                                                      if (vErrors === null) {
                                                        vErrors = [err60];
                                                      } else {
                                                        vErrors.push(err60);
                                                      }
                                                      errors++;
                                                    }
                                                    for (const key24 in data2) {
                                                      if (
                                                        !(
                                                          key24 ===
                                                          'updatePartyProfile'
                                                        )
                                                      ) {
                                                        const err61 = {
                                                          instancePath:
                                                            instancePath +
                                                            '/transaction',
                                                          schemaPath:
                                                            '#/properties/transaction/oneOf/23/additionalProperties',
                                                          keyword:
                                                            'additionalProperties',
                                                          params: {
                                                            additionalProperty:
                                                              key24,
                                                          },
                                                          message:
                                                            'must NOT have additional properties',
                                                        };
                                                        if (vErrors === null) {
                                                          vErrors = [err61];
                                                        } else {
                                                          vErrors.push(err61);
                                                        }
                                                        errors++;
                                                      }
                                                    }
                                                  }
                                                  var _valid0 =
                                                    _errs54 === errors;
                                                  if (_valid0 && valid1) {
                                                    valid1 = false;
                                                    passing0 = [passing0, 23];
                                                  } else {
                                                    if (_valid0) {
                                                      valid1 = true;
                                                      passing0 = 23;
                                                    }
                                                    const _errs56 = errors;
                                                    if (
                                                      data2 &&
                                                      typeof data2 ==
                                                        'object' &&
                                                      !Array.isArray(data2)
                                                    ) {
                                                      if (
                                                        data2.submitAmm ===
                                                        undefined
                                                      ) {
                                                        const err62 = {
                                                          instancePath:
                                                            instancePath +
                                                            '/transaction',
                                                          schemaPath:
                                                            '#/properties/transaction/oneOf/24/required',
                                                          keyword: 'required',
                                                          params: {
                                                            missingProperty:
                                                              'submitAmm',
                                                          },
                                                          message:
                                                            "must have required property '" +
                                                            'submitAmm' +
                                                            "'",
                                                        };
                                                        if (vErrors === null) {
                                                          vErrors = [err62];
                                                        } else {
                                                          vErrors.push(err62);
                                                        }
                                                        errors++;
                                                      }
                                                      for (const key25 in data2) {
                                                        if (
                                                          !(
                                                            key25 ===
                                                            'submitAmm'
                                                          )
                                                        ) {
                                                          const err63 = {
                                                            instancePath:
                                                              instancePath +
                                                              '/transaction',
                                                            schemaPath:
                                                              '#/properties/transaction/oneOf/24/additionalProperties',
                                                            keyword:
                                                              'additionalProperties',
                                                            params: {
                                                              additionalProperty:
                                                                key25,
                                                            },
                                                            message:
                                                              'must NOT have additional properties',
                                                          };
                                                          if (
                                                            vErrors === null
                                                          ) {
                                                            vErrors = [err63];
                                                          } else {
                                                            vErrors.push(err63);
                                                          }
                                                          errors++;
                                                        }
                                                      }
                                                    }
                                                    var _valid0 =
                                                      _errs56 === errors;
                                                    if (_valid0 && valid1) {
                                                      valid1 = false;
                                                      passing0 = [passing0, 24];
                                                    } else {
                                                      if (_valid0) {
                                                        valid1 = true;
                                                        passing0 = 24;
                                                      }
                                                      const _errs58 = errors;
                                                      if (
                                                        data2 &&
                                                        typeof data2 ==
                                                          'object' &&
                                                        !Array.isArray(data2)
                                                      ) {
                                                        if (
                                                          data2.amendAmm ===
                                                          undefined
                                                        ) {
                                                          const err64 = {
                                                            instancePath:
                                                              instancePath +
                                                              '/transaction',
                                                            schemaPath:
                                                              '#/properties/transaction/oneOf/25/required',
                                                            keyword: 'required',
                                                            params: {
                                                              missingProperty:
                                                                'amendAmm',
                                                            },
                                                            message:
                                                              "must have required property '" +
                                                              'amendAmm' +
                                                              "'",
                                                          };
                                                          if (
                                                            vErrors === null
                                                          ) {
                                                            vErrors = [err64];
                                                          } else {
                                                            vErrors.push(err64);
                                                          }
                                                          errors++;
                                                        }
                                                        for (const key26 in data2) {
                                                          if (
                                                            !(
                                                              key26 ===
                                                              'amendAmm'
                                                            )
                                                          ) {
                                                            const err65 = {
                                                              instancePath:
                                                                instancePath +
                                                                '/transaction',
                                                              schemaPath:
                                                                '#/properties/transaction/oneOf/25/additionalProperties',
                                                              keyword:
                                                                'additionalProperties',
                                                              params: {
                                                                additionalProperty:
                                                                  key26,
                                                              },
                                                              message:
                                                                'must NOT have additional properties',
                                                            };
                                                            if (
                                                              vErrors === null
                                                            ) {
                                                              vErrors = [err65];
                                                            } else {
                                                              vErrors.push(
                                                                err65
                                                              );
                                                            }
                                                            errors++;
                                                          }
                                                        }
                                                      }
                                                      var _valid0 =
                                                        _errs58 === errors;
                                                      if (_valid0 && valid1) {
                                                        valid1 = false;
                                                        passing0 = [
                                                          passing0,
                                                          25,
                                                        ];
                                                      } else {
                                                        if (_valid0) {
                                                          valid1 = true;
                                                          passing0 = 25;
                                                        }
                                                        const _errs60 = errors;
                                                        if (
                                                          data2 &&
                                                          typeof data2 ==
                                                            'object' &&
                                                          !Array.isArray(data2)
                                                        ) {
                                                          if (
                                                            data2.cancelAmm ===
                                                            undefined
                                                          ) {
                                                            const err66 = {
                                                              instancePath:
                                                                instancePath +
                                                                '/transaction',
                                                              schemaPath:
                                                                '#/properties/transaction/oneOf/26/required',
                                                              keyword:
                                                                'required',
                                                              params: {
                                                                missingProperty:
                                                                  'cancelAmm',
                                                              },
                                                              message:
                                                                "must have required property '" +
                                                                'cancelAmm' +
                                                                "'",
                                                            };
                                                            if (
                                                              vErrors === null
                                                            ) {
                                                              vErrors = [err66];
                                                            } else {
                                                              vErrors.push(
                                                                err66
                                                              );
                                                            }
                                                            errors++;
                                                          }
                                                          for (const key27 in data2) {
                                                            if (
                                                              !(
                                                                key27 ===
                                                                'cancelAmm'
                                                              )
                                                            ) {
                                                              const err67 = {
                                                                instancePath:
                                                                  instancePath +
                                                                  '/transaction',
                                                                schemaPath:
                                                                  '#/properties/transaction/oneOf/26/additionalProperties',
                                                                keyword:
                                                                  'additionalProperties',
                                                                params: {
                                                                  additionalProperty:
                                                                    key27,
                                                                },
                                                                message:
                                                                  'must NOT have additional properties',
                                                              };
                                                              if (
                                                                vErrors === null
                                                              ) {
                                                                vErrors = [
                                                                  err67,
                                                                ];
                                                              } else {
                                                                vErrors.push(
                                                                  err67
                                                                );
                                                              }
                                                              errors++;
                                                            }
                                                          }
                                                        }
                                                        var _valid0 =
                                                          _errs60 === errors;
                                                        if (_valid0 && valid1) {
                                                          valid1 = false;
                                                          passing0 = [
                                                            passing0,
                                                            26,
                                                          ];
                                                        } else {
                                                          if (_valid0) {
                                                            valid1 = true;
                                                            passing0 = 26;
                                                          }
                                                          const _errs62 =
                                                            errors;
                                                          if (
                                                            data2 &&
                                                            typeof data2 ==
                                                              'object' &&
                                                            !Array.isArray(
                                                              data2
                                                            )
                                                          ) {
                                                            if (
                                                              data2.nodeVote ===
                                                              undefined
                                                            ) {
                                                              const err68 = {
                                                                instancePath:
                                                                  instancePath +
                                                                  '/transaction',
                                                                schemaPath:
                                                                  '#/properties/transaction/oneOf/27/required',
                                                                keyword:
                                                                  'required',
                                                                params: {
                                                                  missingProperty:
                                                                    'nodeVote',
                                                                },
                                                                message:
                                                                  "must have required property '" +
                                                                  'nodeVote' +
                                                                  "'",
                                                              };
                                                              if (
                                                                vErrors === null
                                                              ) {
                                                                vErrors = [
                                                                  err68,
                                                                ];
                                                              } else {
                                                                vErrors.push(
                                                                  err68
                                                                );
                                                              }
                                                              errors++;
                                                            }
                                                            for (const key28 in data2) {
                                                              if (
                                                                !(
                                                                  key28 ===
                                                                  'nodeVote'
                                                                )
                                                              ) {
                                                                const err69 = {
                                                                  instancePath:
                                                                    instancePath +
                                                                    '/transaction',
                                                                  schemaPath:
                                                                    '#/properties/transaction/oneOf/27/additionalProperties',
                                                                  keyword:
                                                                    'additionalProperties',
                                                                  params: {
                                                                    additionalProperty:
                                                                      key28,
                                                                  },
                                                                  message:
                                                                    'must NOT have additional properties',
                                                                };
                                                                if (
                                                                  vErrors ===
                                                                  null
                                                                ) {
                                                                  vErrors = [
                                                                    err69,
                                                                  ];
                                                                } else {
                                                                  vErrors.push(
                                                                    err69
                                                                  );
                                                                }
                                                                errors++;
                                                              }
                                                            }
                                                          }
                                                          var _valid0 =
                                                            _errs62 === errors;
                                                          if (
                                                            _valid0 &&
                                                            valid1
                                                          ) {
                                                            valid1 = false;
                                                            passing0 = [
                                                              passing0,
                                                              27,
                                                            ];
                                                          } else {
                                                            if (_valid0) {
                                                              valid1 = true;
                                                              passing0 = 27;
                                                            }
                                                            const _errs64 =
                                                              errors;
                                                            if (
                                                              data2 &&
                                                              typeof data2 ==
                                                                'object' &&
                                                              !Array.isArray(
                                                                data2
                                                              )
                                                            ) {
                                                              if (
                                                                data2.nodeSignature ===
                                                                undefined
                                                              ) {
                                                                const err70 = {
                                                                  instancePath:
                                                                    instancePath +
                                                                    '/transaction',
                                                                  schemaPath:
                                                                    '#/properties/transaction/oneOf/28/required',
                                                                  keyword:
                                                                    'required',
                                                                  params: {
                                                                    missingProperty:
                                                                      'nodeSignature',
                                                                  },
                                                                  message:
                                                                    "must have required property '" +
                                                                    'nodeSignature' +
                                                                    "'",
                                                                };
                                                                if (
                                                                  vErrors ===
                                                                  null
                                                                ) {
                                                                  vErrors = [
                                                                    err70,
                                                                  ];
                                                                } else {
                                                                  vErrors.push(
                                                                    err70
                                                                  );
                                                                }
                                                                errors++;
                                                              }
                                                              for (const key29 in data2) {
                                                                if (
                                                                  !(
                                                                    key29 ===
                                                                    'nodeSignature'
                                                                  )
                                                                ) {
                                                                  const err71 =
                                                                    {
                                                                      instancePath:
                                                                        instancePath +
                                                                        '/transaction',
                                                                      schemaPath:
                                                                        '#/properties/transaction/oneOf/28/additionalProperties',
                                                                      keyword:
                                                                        'additionalProperties',
                                                                      params: {
                                                                        additionalProperty:
                                                                          key29,
                                                                      },
                                                                      message:
                                                                        'must NOT have additional properties',
                                                                    };
                                                                  if (
                                                                    vErrors ===
                                                                    null
                                                                  ) {
                                                                    vErrors = [
                                                                      err71,
                                                                    ];
                                                                  } else {
                                                                    vErrors.push(
                                                                      err71
                                                                    );
                                                                  }
                                                                  errors++;
                                                                }
                                                              }
                                                            }
                                                            var _valid0 =
                                                              _errs64 ===
                                                              errors;
                                                            if (
                                                              _valid0 &&
                                                              valid1
                                                            ) {
                                                              valid1 = false;
                                                              passing0 = [
                                                                passing0,
                                                                28,
                                                              ];
                                                            } else {
                                                              if (_valid0) {
                                                                valid1 = true;
                                                                passing0 = 28;
                                                              }
                                                              const _errs66 =
                                                                errors;
                                                              if (
                                                                data2 &&
                                                                typeof data2 ==
                                                                  'object' &&
                                                                !Array.isArray(
                                                                  data2
                                                                )
                                                              ) {
                                                                if (
                                                                  data2.chainEvent ===
                                                                  undefined
                                                                ) {
                                                                  const err72 =
                                                                    {
                                                                      instancePath:
                                                                        instancePath +
                                                                        '/transaction',
                                                                      schemaPath:
                                                                        '#/properties/transaction/oneOf/29/required',
                                                                      keyword:
                                                                        'required',
                                                                      params: {
                                                                        missingProperty:
                                                                          'chainEvent',
                                                                      },
                                                                      message:
                                                                        "must have required property '" +
                                                                        'chainEvent' +
                                                                        "'",
                                                                    };
                                                                  if (
                                                                    vErrors ===
                                                                    null
                                                                  ) {
                                                                    vErrors = [
                                                                      err72,
                                                                    ];
                                                                  } else {
                                                                    vErrors.push(
                                                                      err72
                                                                    );
                                                                  }
                                                                  errors++;
                                                                }
                                                                for (const key30 in data2) {
                                                                  if (
                                                                    !(
                                                                      key30 ===
                                                                      'chainEvent'
                                                                    )
                                                                  ) {
                                                                    const err73 =
                                                                      {
                                                                        instancePath:
                                                                          instancePath +
                                                                          '/transaction',
                                                                        schemaPath:
                                                                          '#/properties/transaction/oneOf/29/additionalProperties',
                                                                        keyword:
                                                                          'additionalProperties',
                                                                        params:
                                                                          {
                                                                            additionalProperty:
                                                                              key30,
                                                                          },
                                                                        message:
                                                                          'must NOT have additional properties',
                                                                      };
                                                                    if (
                                                                      vErrors ===
                                                                      null
                                                                    ) {
                                                                      vErrors =
                                                                        [err73];
                                                                    } else {
                                                                      vErrors.push(
                                                                        err73
                                                                      );
                                                                    }
                                                                    errors++;
                                                                  }
                                                                }
                                                              }
                                                              var _valid0 =
                                                                _errs66 ===
                                                                errors;
                                                              if (
                                                                _valid0 &&
                                                                valid1
                                                              ) {
                                                                valid1 = false;
                                                                passing0 = [
                                                                  passing0,
                                                                  29,
                                                                ];
                                                              } else {
                                                                if (_valid0) {
                                                                  valid1 = true;
                                                                  passing0 = 29;
                                                                }
                                                                const _errs68 =
                                                                  errors;
                                                                if (
                                                                  data2 &&
                                                                  typeof data2 ==
                                                                    'object' &&
                                                                  !Array.isArray(
                                                                    data2
                                                                  )
                                                                ) {
                                                                  if (
                                                                    data2.keyRotateSubmission ===
                                                                    undefined
                                                                  ) {
                                                                    const err74 =
                                                                      {
                                                                        instancePath:
                                                                          instancePath +
                                                                          '/transaction',
                                                                        schemaPath:
                                                                          '#/properties/transaction/oneOf/30/required',
                                                                        keyword:
                                                                          'required',
                                                                        params:
                                                                          {
                                                                            missingProperty:
                                                                              'keyRotateSubmission',
                                                                          },
                                                                        message:
                                                                          "must have required property '" +
                                                                          'keyRotateSubmission' +
                                                                          "'",
                                                                      };
                                                                    if (
                                                                      vErrors ===
                                                                      null
                                                                    ) {
                                                                      vErrors =
                                                                        [err74];
                                                                    } else {
                                                                      vErrors.push(
                                                                        err74
                                                                      );
                                                                    }
                                                                    errors++;
                                                                  }
                                                                  for (const key31 in data2) {
                                                                    if (
                                                                      !(
                                                                        key31 ===
                                                                        'keyRotateSubmission'
                                                                      )
                                                                    ) {
                                                                      const err75 =
                                                                        {
                                                                          instancePath:
                                                                            instancePath +
                                                                            '/transaction',
                                                                          schemaPath:
                                                                            '#/properties/transaction/oneOf/30/additionalProperties',
                                                                          keyword:
                                                                            'additionalProperties',
                                                                          params:
                                                                            {
                                                                              additionalProperty:
                                                                                key31,
                                                                            },
                                                                          message:
                                                                            'must NOT have additional properties',
                                                                        };
                                                                      if (
                                                                        vErrors ===
                                                                        null
                                                                      ) {
                                                                        vErrors =
                                                                          [
                                                                            err75,
                                                                          ];
                                                                      } else {
                                                                        vErrors.push(
                                                                          err75
                                                                        );
                                                                      }
                                                                      errors++;
                                                                    }
                                                                  }
                                                                }
                                                                var _valid0 =
                                                                  _errs68 ===
                                                                  errors;
                                                                if (
                                                                  _valid0 &&
                                                                  valid1
                                                                ) {
                                                                  valid1 = false;
                                                                  passing0 = [
                                                                    passing0,
                                                                    30,
                                                                  ];
                                                                } else {
                                                                  if (_valid0) {
                                                                    valid1 = true;
                                                                    passing0 = 30;
                                                                  }
                                                                  const _errs70 =
                                                                    errors;
                                                                  if (
                                                                    data2 &&
                                                                    typeof data2 ==
                                                                      'object' &&
                                                                    !Array.isArray(
                                                                      data2
                                                                    )
                                                                  ) {
                                                                    if (
                                                                      data2.stateVariableProposal ===
                                                                      undefined
                                                                    ) {
                                                                      const err76 =
                                                                        {
                                                                          instancePath:
                                                                            instancePath +
                                                                            '/transaction',
                                                                          schemaPath:
                                                                            '#/properties/transaction/oneOf/31/required',
                                                                          keyword:
                                                                            'required',
                                                                          params:
                                                                            {
                                                                              missingProperty:
                                                                                'stateVariableProposal',
                                                                            },
                                                                          message:
                                                                            "must have required property '" +
                                                                            'stateVariableProposal' +
                                                                            "'",
                                                                        };
                                                                      if (
                                                                        vErrors ===
                                                                        null
                                                                      ) {
                                                                        vErrors =
                                                                          [
                                                                            err76,
                                                                          ];
                                                                      } else {
                                                                        vErrors.push(
                                                                          err76
                                                                        );
                                                                      }
                                                                      errors++;
                                                                    }
                                                                    for (const key32 in data2) {
                                                                      if (
                                                                        !(
                                                                          key32 ===
                                                                          'stateVariableProposal'
                                                                        )
                                                                      ) {
                                                                        const err77 =
                                                                          {
                                                                            instancePath:
                                                                              instancePath +
                                                                              '/transaction',
                                                                            schemaPath:
                                                                              '#/properties/transaction/oneOf/31/additionalProperties',
                                                                            keyword:
                                                                              'additionalProperties',
                                                                            params:
                                                                              {
                                                                                additionalProperty:
                                                                                  key32,
                                                                              },
                                                                            message:
                                                                              'must NOT have additional properties',
                                                                          };
                                                                        if (
                                                                          vErrors ===
                                                                          null
                                                                        ) {
                                                                          vErrors =
                                                                            [
                                                                              err77,
                                                                            ];
                                                                        } else {
                                                                          vErrors.push(
                                                                            err77
                                                                          );
                                                                        }
                                                                        errors++;
                                                                      }
                                                                    }
                                                                  }
                                                                  var _valid0 =
                                                                    _errs70 ===
                                                                    errors;
                                                                  if (
                                                                    _valid0 &&
                                                                    valid1
                                                                  ) {
                                                                    valid1 = false;
                                                                    passing0 = [
                                                                      passing0,
                                                                      31,
                                                                    ];
                                                                  } else {
                                                                    if (
                                                                      _valid0
                                                                    ) {
                                                                      valid1 = true;
                                                                      passing0 = 31;
                                                                    }
                                                                    const _errs72 =
                                                                      errors;
                                                                    if (
                                                                      data2 &&
                                                                      typeof data2 ==
                                                                        'object' &&
                                                                      !Array.isArray(
                                                                        data2
                                                                      )
                                                                    ) {
                                                                      if (
                                                                        data2.validatorHeartbeat ===
                                                                        undefined
                                                                      ) {
                                                                        const err78 =
                                                                          {
                                                                            instancePath:
                                                                              instancePath +
                                                                              '/transaction',
                                                                            schemaPath:
                                                                              '#/properties/transaction/oneOf/32/required',
                                                                            keyword:
                                                                              'required',
                                                                            params:
                                                                              {
                                                                                missingProperty:
                                                                                  'validatorHeartbeat',
                                                                              },
                                                                            message:
                                                                              "must have required property '" +
                                                                              'validatorHeartbeat' +
                                                                              "'",
                                                                          };
                                                                        if (
                                                                          vErrors ===
                                                                          null
                                                                        ) {
                                                                          vErrors =
                                                                            [
                                                                              err78,
                                                                            ];
                                                                        } else {
                                                                          vErrors.push(
                                                                            err78
                                                                          );
                                                                        }
                                                                        errors++;
                                                                      }
                                                                      for (const key33 in data2) {
                                                                        if (
                                                                          !(
                                                                            key33 ===
                                                                            'validatorHeartbeat'
                                                                          )
                                                                        ) {
                                                                          const err79 =
                                                                            {
                                                                              instancePath:
                                                                                instancePath +
                                                                                '/transaction',
                                                                              schemaPath:
                                                                                '#/properties/transaction/oneOf/32/additionalProperties',
                                                                              keyword:
                                                                                'additionalProperties',
                                                                              params:
                                                                                {
                                                                                  additionalProperty:
                                                                                    key33,
                                                                                },
                                                                              message:
                                                                                'must NOT have additional properties',
                                                                            };
                                                                          if (
                                                                            vErrors ===
                                                                            null
                                                                          ) {
                                                                            vErrors =
                                                                              [
                                                                                err79,
                                                                              ];
                                                                          } else {
                                                                            vErrors.push(
                                                                              err79
                                                                            );
                                                                          }
                                                                          errors++;
                                                                        }
                                                                      }
                                                                    }
                                                                    var _valid0 =
                                                                      _errs72 ===
                                                                      errors;
                                                                    if (
                                                                      _valid0 &&
                                                                      valid1
                                                                    ) {
                                                                      valid1 = false;
                                                                      passing0 =
                                                                        [
                                                                          passing0,
                                                                          32,
                                                                        ];
                                                                    } else {
                                                                      if (
                                                                        _valid0
                                                                      ) {
                                                                        valid1 = true;
                                                                        passing0 = 32;
                                                                      }
                                                                      const _errs74 =
                                                                        errors;
                                                                      if (
                                                                        data2 &&
                                                                        typeof data2 ==
                                                                          'object' &&
                                                                        !Array.isArray(
                                                                          data2
                                                                        )
                                                                      ) {
                                                                        if (
                                                                          data2.ethereumKeyRotateSubmission ===
                                                                          undefined
                                                                        ) {
                                                                          const err80 =
                                                                            {
                                                                              instancePath:
                                                                                instancePath +
                                                                                '/transaction',
                                                                              schemaPath:
                                                                                '#/properties/transaction/oneOf/33/required',
                                                                              keyword:
                                                                                'required',
                                                                              params:
                                                                                {
                                                                                  missingProperty:
                                                                                    'ethereumKeyRotateSubmission',
                                                                                },
                                                                              message:
                                                                                "must have required property '" +
                                                                                'ethereumKeyRotateSubmission' +
                                                                                "'",
                                                                            };
                                                                          if (
                                                                            vErrors ===
                                                                            null
                                                                          ) {
                                                                            vErrors =
                                                                              [
                                                                                err80,
                                                                              ];
                                                                          } else {
                                                                            vErrors.push(
                                                                              err80
                                                                            );
                                                                          }
                                                                          errors++;
                                                                        }
                                                                        for (const key34 in data2) {
                                                                          if (
                                                                            !(
                                                                              key34 ===
                                                                              'ethereumKeyRotateSubmission'
                                                                            )
                                                                          ) {
                                                                            const err81 =
                                                                              {
                                                                                instancePath:
                                                                                  instancePath +
                                                                                  '/transaction',
                                                                                schemaPath:
                                                                                  '#/properties/transaction/oneOf/33/additionalProperties',
                                                                                keyword:
                                                                                  'additionalProperties',
                                                                                params:
                                                                                  {
                                                                                    additionalProperty:
                                                                                      key34,
                                                                                  },
                                                                                message:
                                                                                  'must NOT have additional properties',
                                                                              };
                                                                            if (
                                                                              vErrors ===
                                                                              null
                                                                            ) {
                                                                              vErrors =
                                                                                [
                                                                                  err81,
                                                                                ];
                                                                            } else {
                                                                              vErrors.push(
                                                                                err81
                                                                              );
                                                                            }
                                                                            errors++;
                                                                          }
                                                                        }
                                                                      }
                                                                      var _valid0 =
                                                                        _errs74 ===
                                                                        errors;
                                                                      if (
                                                                        _valid0 &&
                                                                        valid1
                                                                      ) {
                                                                        valid1 = false;
                                                                        passing0 =
                                                                          [
                                                                            passing0,
                                                                            33,
                                                                          ];
                                                                      } else {
                                                                        if (
                                                                          _valid0
                                                                        ) {
                                                                          valid1 = true;
                                                                          passing0 = 33;
                                                                        }
                                                                        const _errs76 =
                                                                          errors;
                                                                        if (
                                                                          data2 &&
                                                                          typeof data2 ==
                                                                            'object' &&
                                                                          !Array.isArray(
                                                                            data2
                                                                          )
                                                                        ) {
                                                                          if (
                                                                            data2.protocolUpgradeProposal ===
                                                                            undefined
                                                                          ) {
                                                                            const err82 =
                                                                              {
                                                                                instancePath:
                                                                                  instancePath +
                                                                                  '/transaction',
                                                                                schemaPath:
                                                                                  '#/properties/transaction/oneOf/34/required',
                                                                                keyword:
                                                                                  'required',
                                                                                params:
                                                                                  {
                                                                                    missingProperty:
                                                                                      'protocolUpgradeProposal',
                                                                                  },
                                                                                message:
                                                                                  "must have required property '" +
                                                                                  'protocolUpgradeProposal' +
                                                                                  "'",
                                                                              };
                                                                            if (
                                                                              vErrors ===
                                                                              null
                                                                            ) {
                                                                              vErrors =
                                                                                [
                                                                                  err82,
                                                                                ];
                                                                            } else {
                                                                              vErrors.push(
                                                                                err82
                                                                              );
                                                                            }
                                                                            errors++;
                                                                          }
                                                                          for (const key35 in data2) {
                                                                            if (
                                                                              !(
                                                                                key35 ===
                                                                                'protocolUpgradeProposal'
                                                                              )
                                                                            ) {
                                                                              const err83 =
                                                                                {
                                                                                  instancePath:
                                                                                    instancePath +
                                                                                    '/transaction',
                                                                                  schemaPath:
                                                                                    '#/properties/transaction/oneOf/34/additionalProperties',
                                                                                  keyword:
                                                                                    'additionalProperties',
                                                                                  params:
                                                                                    {
                                                                                      additionalProperty:
                                                                                        key35,
                                                                                    },
                                                                                  message:
                                                                                    'must NOT have additional properties',
                                                                                };
                                                                              if (
                                                                                vErrors ===
                                                                                null
                                                                              ) {
                                                                                vErrors =
                                                                                  [
                                                                                    err83,
                                                                                  ];
                                                                              } else {
                                                                                vErrors.push(
                                                                                  err83
                                                                                );
                                                                              }
                                                                              errors++;
                                                                            }
                                                                          }
                                                                        }
                                                                        var _valid0 =
                                                                          _errs76 ===
                                                                          errors;
                                                                        if (
                                                                          _valid0 &&
                                                                          valid1
                                                                        ) {
                                                                          valid1 = false;
                                                                          passing0 =
                                                                            [
                                                                              passing0,
                                                                              34,
                                                                            ];
                                                                        } else {
                                                                          if (
                                                                            _valid0
                                                                          ) {
                                                                            valid1 = true;
                                                                            passing0 = 34;
                                                                          }
                                                                          const _errs78 =
                                                                            errors;
                                                                          if (
                                                                            data2 &&
                                                                            typeof data2 ==
                                                                              'object' &&
                                                                            !Array.isArray(
                                                                              data2
                                                                            )
                                                                          ) {
                                                                            if (
                                                                              data2.issueSignatures ===
                                                                              undefined
                                                                            ) {
                                                                              const err84 =
                                                                                {
                                                                                  instancePath:
                                                                                    instancePath +
                                                                                    '/transaction',
                                                                                  schemaPath:
                                                                                    '#/properties/transaction/oneOf/35/required',
                                                                                  keyword:
                                                                                    'required',
                                                                                  params:
                                                                                    {
                                                                                      missingProperty:
                                                                                        'issueSignatures',
                                                                                    },
                                                                                  message:
                                                                                    "must have required property '" +
                                                                                    'issueSignatures' +
                                                                                    "'",
                                                                                };
                                                                              if (
                                                                                vErrors ===
                                                                                null
                                                                              ) {
                                                                                vErrors =
                                                                                  [
                                                                                    err84,
                                                                                  ];
                                                                              } else {
                                                                                vErrors.push(
                                                                                  err84
                                                                                );
                                                                              }
                                                                              errors++;
                                                                            }
                                                                            for (const key36 in data2) {
                                                                              if (
                                                                                !(
                                                                                  key36 ===
                                                                                  'issueSignatures'
                                                                                )
                                                                              ) {
                                                                                const err85 =
                                                                                  {
                                                                                    instancePath:
                                                                                      instancePath +
                                                                                      '/transaction',
                                                                                    schemaPath:
                                                                                      '#/properties/transaction/oneOf/35/additionalProperties',
                                                                                    keyword:
                                                                                      'additionalProperties',
                                                                                    params:
                                                                                      {
                                                                                        additionalProperty:
                                                                                          key36,
                                                                                      },
                                                                                    message:
                                                                                      'must NOT have additional properties',
                                                                                  };
                                                                                if (
                                                                                  vErrors ===
                                                                                  null
                                                                                ) {
                                                                                  vErrors =
                                                                                    [
                                                                                      err85,
                                                                                    ];
                                                                                } else {
                                                                                  vErrors.push(
                                                                                    err85
                                                                                  );
                                                                                }
                                                                                errors++;
                                                                              }
                                                                            }
                                                                          }
                                                                          var _valid0 =
                                                                            _errs78 ===
                                                                            errors;
                                                                          if (
                                                                            _valid0 &&
                                                                            valid1
                                                                          ) {
                                                                            valid1 = false;
                                                                            passing0 =
                                                                              [
                                                                                passing0,
                                                                                35,
                                                                              ];
                                                                          } else {
                                                                            if (
                                                                              _valid0
                                                                            ) {
                                                                              valid1 = true;
                                                                              passing0 = 35;
                                                                            }
                                                                            const _errs80 =
                                                                              errors;
                                                                            if (
                                                                              data2 &&
                                                                              typeof data2 ==
                                                                                'object' &&
                                                                              !Array.isArray(
                                                                                data2
                                                                              )
                                                                            ) {
                                                                              if (
                                                                                data2.oracleDataSubmission ===
                                                                                undefined
                                                                              ) {
                                                                                const err86 =
                                                                                  {
                                                                                    instancePath:
                                                                                      instancePath +
                                                                                      '/transaction',
                                                                                    schemaPath:
                                                                                      '#/properties/transaction/oneOf/36/required',
                                                                                    keyword:
                                                                                      'required',
                                                                                    params:
                                                                                      {
                                                                                        missingProperty:
                                                                                          'oracleDataSubmission',
                                                                                      },
                                                                                    message:
                                                                                      "must have required property '" +
                                                                                      'oracleDataSubmission' +
                                                                                      "'",
                                                                                  };
                                                                                if (
                                                                                  vErrors ===
                                                                                  null
                                                                                ) {
                                                                                  vErrors =
                                                                                    [
                                                                                      err86,
                                                                                    ];
                                                                                } else {
                                                                                  vErrors.push(
                                                                                    err86
                                                                                  );
                                                                                }
                                                                                errors++;
                                                                              }
                                                                              for (const key37 in data2) {
                                                                                if (
                                                                                  !(
                                                                                    key37 ===
                                                                                    'oracleDataSubmission'
                                                                                  )
                                                                                ) {
                                                                                  const err87 =
                                                                                    {
                                                                                      instancePath:
                                                                                        instancePath +
                                                                                        '/transaction',
                                                                                      schemaPath:
                                                                                        '#/properties/transaction/oneOf/36/additionalProperties',
                                                                                      keyword:
                                                                                        'additionalProperties',
                                                                                      params:
                                                                                        {
                                                                                          additionalProperty:
                                                                                            key37,
                                                                                        },
                                                                                      message:
                                                                                        'must NOT have additional properties',
                                                                                    };
                                                                                  if (
                                                                                    vErrors ===
                                                                                    null
                                                                                  ) {
                                                                                    vErrors =
                                                                                      [
                                                                                        err87,
                                                                                      ];
                                                                                  } else {
                                                                                    vErrors.push(
                                                                                      err87
                                                                                    );
                                                                                  }
                                                                                  errors++;
                                                                                }
                                                                              }
                                                                            }
                                                                            var _valid0 =
                                                                              _errs80 ===
                                                                              errors;
                                                                            if (
                                                                              _valid0 &&
                                                                              valid1
                                                                            ) {
                                                                              valid1 = false;
                                                                              passing0 =
                                                                                [
                                                                                  passing0,
                                                                                  36,
                                                                                ];
                                                                            } else {
                                                                              if (
                                                                                _valid0
                                                                              ) {
                                                                                valid1 = true;
                                                                                passing0 = 36;
                                                                              }
                                                                              const _errs82 =
                                                                                errors;
                                                                              if (
                                                                                data2 &&
                                                                                typeof data2 ==
                                                                                  'object' &&
                                                                                !Array.isArray(
                                                                                  data2
                                                                                )
                                                                              ) {
                                                                                if (
                                                                                  data2.delayedTransactionsWrapper ===
                                                                                  undefined
                                                                                ) {
                                                                                  const err88 =
                                                                                    {
                                                                                      instancePath:
                                                                                        instancePath +
                                                                                        '/transaction',
                                                                                      schemaPath:
                                                                                        '#/properties/transaction/oneOf/37/required',
                                                                                      keyword:
                                                                                        'required',
                                                                                      params:
                                                                                        {
                                                                                          missingProperty:
                                                                                            'delayedTransactionsWrapper',
                                                                                        },
                                                                                      message:
                                                                                        "must have required property '" +
                                                                                        'delayedTransactionsWrapper' +
                                                                                        "'",
                                                                                    };
                                                                                  if (
                                                                                    vErrors ===
                                                                                    null
                                                                                  ) {
                                                                                    vErrors =
                                                                                      [
                                                                                        err88,
                                                                                      ];
                                                                                  } else {
                                                                                    vErrors.push(
                                                                                      err88
                                                                                    );
                                                                                  }
                                                                                  errors++;
                                                                                }
                                                                                for (const key38 in data2) {
                                                                                  if (
                                                                                    !(
                                                                                      key38 ===
                                                                                      'delayedTransactionsWrapper'
                                                                                    )
                                                                                  ) {
                                                                                    const err89 =
                                                                                      {
                                                                                        instancePath:
                                                                                          instancePath +
                                                                                          '/transaction',
                                                                                        schemaPath:
                                                                                          '#/properties/transaction/oneOf/37/additionalProperties',
                                                                                        keyword:
                                                                                          'additionalProperties',
                                                                                        params:
                                                                                          {
                                                                                            additionalProperty:
                                                                                              key38,
                                                                                          },
                                                                                        message:
                                                                                          'must NOT have additional properties',
                                                                                      };
                                                                                    if (
                                                                                      vErrors ===
                                                                                      null
                                                                                    ) {
                                                                                      vErrors =
                                                                                        [
                                                                                          err89,
                                                                                        ];
                                                                                    } else {
                                                                                      vErrors.push(
                                                                                        err89
                                                                                      );
                                                                                    }
                                                                                    errors++;
                                                                                  }
                                                                                }
                                                                              }
                                                                              var _valid0 =
                                                                                _errs82 ===
                                                                                errors;
                                                                              if (
                                                                                _valid0 &&
                                                                                valid1
                                                                              ) {
                                                                                valid1 = false;
                                                                                passing0 =
                                                                                  [
                                                                                    passing0,
                                                                                    37,
                                                                                  ];
                                                                              } else {
                                                                                if (
                                                                                  _valid0
                                                                                ) {
                                                                                  valid1 = true;
                                                                                  passing0 = 37;
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (!valid1) {
        const err90 = {
          instancePath: instancePath + '/transaction',
          schemaPath: '#/properties/transaction/oneOf',
          keyword: 'oneOf',
          params: { passingSchemas: passing0 },
          message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
          vErrors = [err90];
        } else {
          vErrors.push(err90);
        }
        errors++;
      } else {
        errors = _errs7;
        if (vErrors !== null) {
          if (_errs7) {
            vErrors.length = _errs7;
          } else {
            vErrors = null;
          }
        }
      }
      if (errors > 0) {
        const emErrs4 = [];
        for (const err91 of vErrors) {
          if (
            err91.keyword !== 'errorMessage' &&
            !err91.emUsed &&
            (err91.instancePath === instancePath + '/transaction' ||
              (err91.instancePath.indexOf(instancePath + '/transaction') ===
                0 &&
                err91.instancePath[instancePath + '/transaction'.length] ===
                  '/')) &&
            err91.schemaPath.indexOf('#/properties/transaction') === 0 &&
            err91.schemaPath['#/properties/transaction'.length] === '/'
          ) {
            emErrs4.push(err91);
            err91.emUsed = true;
          }
        }
        if (emErrs4.length) {
          const err92 = {
            instancePath: instancePath + '/transaction',
            schemaPath: '#/properties/transaction/errorMessage',
            keyword: 'errorMessage',
            params: { errors: emErrs4 },
            message: 'Unsupported transaction type',
          };
          if (vErrors === null) {
            vErrors = [err92];
          } else {
            vErrors.push(err92);
          }
          errors++;
        }
        const emErrs5 = [];
        for (const err93 of vErrors) {
          if (!err93.emUsed) {
            emErrs5.push(err93);
          }
        }
        vErrors = emErrs5;
        errors = emErrs5.length;
      }
    }
  } else {
    const err94 = {
      instancePath,
      schemaPath: '#/type',
      keyword: 'type',
      params: { type: 'object' },
      message: 'must be object',
    };
    if (vErrors === null) {
      vErrors = [err94];
    } else {
      vErrors.push(err94);
    }
    errors++;
  }
  if (errors > 0) {
    const emErrs6 = [];
    for (const err95 of vErrors) {
      if (
        err95.keyword !== 'errorMessage' &&
        !err95.emUsed &&
        (err95.instancePath === instancePath ||
          (err95.instancePath.indexOf(instancePath) === 0 &&
            err95.instancePath[instancePath.length] === '/')) &&
        err95.schemaPath.indexOf('#') === 0 &&
        err95.schemaPath['#'.length] === '/'
      ) {
        emErrs6.push(err95);
        err95.emUsed = true;
      }
    }
    if (emErrs6.length) {
      const err96 = {
        instancePath,
        schemaPath: '#/errorMessage',
        keyword: 'errorMessage',
        params: { errors: emErrs6 },
        message:
          '`client.send_transaction` must only be given `publicKey`, `transaction` and `sendingMode`',
      };
      if (vErrors === null) {
        vErrors = [err96];
      } else {
        vErrors.push(err96);
      }
      errors++;
    }
    const emErrs7 = [];
    for (const err97 of vErrors) {
      if (!err97.emUsed) {
        emErrs7.push(err97);
      }
    }
    vErrors = emErrs7;
    errors = emErrs7.length;
  }
  validate14.errors = vErrors;
  return errors === 0;
}

export { validate14 as default, validate };
